import {
  EventListener as utilities,
  AlertsClear,
  AlertClose,
  AlertReset,
  Modal,
  // Cookiebar,
  ImageViewer,
} from '@morsedigital/morse-utils';

// import MobileFooterPanels from './footer/footer_panels';
// import MobileFooterAppText from './footer/footer_app_text';

// import SurveyBanner from './survey_banner/survey_banner';

import fontSizing from './accessibility/font_sizing';
import ColorContrast from './accessibility/colour_contrast';
import feedback from './feedback';

(() => {
  // Accessibility
  fontSizing();
  ColorContrast();
})();

// SurveyBanner
// (() => {
//   SurveyBanner();
// })();

// Footer
(() => {
  // MobileFooterPanels();
  // MobileFooterAppText();
})();

(() => {
  // Utility functions
  const modules = [
    AlertsClear(),
    AlertClose(),
    AlertReset(),
    // Cookiebar('cookie-prompt'), // Should be id of Cookie prompt DOM Node
    Modal(),
    ImageViewer(),
  ];

  utilities(modules);

  const cookie = document.getElementById('cookie-prompt');
  const survey = document.getElementById('survey-modal');
  if (cookie) {
    cookie.focus();
  } else if (survey) {
    survey.focus();
  }
})();

(() => {
  feedback();
})();
