import './content-slider.scss';

export default () => {
  const sliders = [...document.querySelectorAll('[data-content-slider]')];
  if (sliders.length > 0) {
    import(/* webpackChunkName: "content_slider" */ './content_slider').then(({ default: ContentSlider }) => {
      ContentSlider();
    });
  }
};
