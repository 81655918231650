import '../stylesheets/application.scss';
import '../modules/primary-nav/stylesheets/index.scss';
import '../modules/venue_nav/stylesheets/index.scss';
import '../components/facility-finder/stylesheets/index.scss';
import '../components/weekly_prog/stylesheets/index.scss';
import '../components/loader/styles.css';

import '../images/better-pdf-logo.gif';
import '../images/accessibility.svg';
import '../images/contact.svg';
import '../images/circular.svg';
import '../images/facilities.svg';
import '../images/general.svg';

import '../modernizr/modernizr';
import '../modules';
/* eslint-enable */

function importAll(r) {
  return r.keys().map(r);
}

importAll(require.context('../images/.', true, /\.(png|gif|jpe?g|svg|webp)$/));

// Lazy load images

// Contact honeypot
const honeyPot = document.getElementById('contact-honeypot');
if (honeyPot) {
  honeyPot.style.display = 'none';
}
// End Contact honeypot
