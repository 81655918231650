export default () => {
  const identifier = 'refund_form_payment_method';
  const paymentMethod = document.getElementById(identifier);
  const refundForm = document.getElementById('new_refund_form');

  const name = document.getElementById('refund_form_payee_name');
  const address = document.getElementById('refund_form_payee_address');
  const postcode = document.getElementById('refund_form_payee_postcode');
  const accountName = document.getElementById('refund_form_payee_account_name');
  const accountNumber = document.getElementById(
    'refund_form_payee_account_number'
  );
  const sortCode = document.getElementById('refund_form_payee_sort_code');

  const paymentCheque = document.getElementById('cheque');
  const paymentBacs = document.getElementById('bacs');
  const tsandcs = document.getElementById('tsandcs');

  if (typeof paymentMethod === 'undefined') {
    return;
  }

  if (paymentMethod === null) {
    return;
  }

  function refundPayment() {
    if (paymentMethod.options[paymentMethod.selectedIndex].text === 'Bacs') {
      accountName.value = '';
      accountNumber.value = '';
      sortCode.value = '';
      paymentCheque.style.display = 'none';
      paymentBacs.style.display = 'block';
    }
    if (paymentMethod.options[paymentMethod.selectedIndex].text === 'Cheque') {
      name.value = '';
      address.value = '';
      postcode.value = '';
      paymentBacs.style.display = 'none';
      paymentCheque.style.display = 'block';
    }
    refundForm.dataset.remote = true;

    if (typeof tsandcs === 'undefined') {
      return;
    }

    if (tsandcs === null) {
      return;
    }

    tsandcs.style.display = 'none';
  }

  paymentMethod.onchange = function() {
    refundPayment();
  };
};
