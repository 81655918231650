/* global module */
import { createStore, applyMiddleware, compose } from 'redux';

// import thunk from 'redux-thunk';
import setupDB from './database/setup';
// Middleware
import NavMiddleware from './middleware/nav_middleware';

// Reducer
import rootReducer from './reducers';

const finalCreateStore = ({ db }) => {
  const composeEnhancers = compose;
  const middleware = [NavMiddleware(db)];
  return composeEnhancers(
    // Middleware you want to use in development:
    applyMiddleware(...middleware)
  )(createStore);
};

export default async initProps => {
  // const { navitems } = initProps;
  let navitems = [];
  let database = null;
  try {
    database = await setupDB();
    navitems = await database.db.getAllStore('navitems');
  } catch (e) {
    console.warn('IndexDb not supported');
  }

  const props = navitems
    ? { ...initProps, active: [], navitems, open: false }
    : { ...initProps, active: [], navitems: [], open: false };

  const composer = finalCreateStore(database);
  const store = composer(rootReducer, props);

  const { dispatch } = store;
  window.navDispatch = dispatch; // @ade I know this is nasty needs to be re-factored

  // /* istanbul ignore if  */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers/index', async () => {
      const nextReducer = await import('./reducers/index');
      store.replaceReducer(nextReducer.default);
    });
  }

  return store;
};
