import Manager from '../../manage_events';

import { getElement } from '../helpers';

import { SetActive } from '../actions/navitems';

const ClickHandler = dispatch => e => {
  e.preventDefault();
  dispatch(SetActive(e.target.id));
};

const setRoots = dispatch => ({ id, sub }) => {
  const item = getElement(id);
  if (!item) return;

  if (sub) {
    // Manager.removeEventListener(item, 'click');
    Manager.addEventListener(item, 'click', ClickHandler(dispatch));
    sub.forEach(setRoots(dispatch));
  }
};

const setActive = (active = [], depth = 0) => ({ id, sub }) => {
  const item = getElement(id);
  if (!item) return;
  const hidden = active.length === depth ? false : !active.includes(id);

  item.parentElement.setAttribute('aria-hidden', hidden.toString());

  if (sub && active.includes(id)) {
    sub.forEach(setActive(active, depth + 1));
  }
};

const selectRoot = ({ navitems, active }) => {
  navitems.forEach(setActive(active));
};

export default ({ dispatch, getState }, watcher) => {
  const { device: dev, navitems: nis } = getState();

  if (nis && dev === 'mobile') {
    nis.forEach(setRoots(dispatch));
  }

  // Subscribe to navitems changes
  watcher(['navitems'], ({ device, navitems }) => {
    if (device !== 'mobile') return;
    if (!navitems) return;
    navitems.forEach(setRoots(dispatch));
  });

  watcher(['active'], selectRoot);

  watcher(['device'], ({ device, navitems }) => {
    if (device !== 'mobile') return;
    navitems.forEach(setRoots(dispatch));
  });
};
