export default () => {

  var firstDropdown = document.getElementById('corporate_memberships_registration_membership_type_id');
  
  if(firstDropdown){    
    var secondDropdown = document.getElementById('corporate_memberships_registration_venue_id');
    var action = firstDropdown.form.action;
    var parts = action.split('id=')
    var uuid = parts[parts.length - 1];

    firstDropdown.addEventListener('change', function() {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', '/corporate-memberships/companies/' + uuid + '/membership-types/' + encodeURIComponent(firstDropdown.value));
      xhr.responseType = 'json';
      xhr.onload = function() {
        if (xhr.status === 200) {
          // Clear existing options
          secondDropdown.innerHTML = '';
          var optionElement = document.createElement('option');
          secondDropdown.appendChild(optionElement);

          // Add new options based on the response
          xhr.response.forEach(function(option) {
            var optionElement = document.createElement('option');
            optionElement.value = option.id;
            optionElement.textContent = option.title;
            secondDropdown.appendChild(optionElement);
          });
        }
      };
      xhr.send();
    });
  }



}
