import DatePicker from './datepicker';
import SurveyBanner from './survey_banner';
import GeoLocation from './geolocation';
import VenueNav from './venue_nav';

(() => {
  SurveyBanner();
})();

(() => {
  // Tabbed Content
  const tabbedContentElements = [...document.querySelectorAll('.tabbed-content')];

  if (tabbedContentElements.length > 0) {
    import(/* webpackChunkName: "vanilla-tabbed-content" */ '@morsedigital/vanilla-tabbed-content').then(
      ({ default: TabbedContentClass }) => {
        tabbedContentElements.map(
          tabbedContent =>
            new TabbedContentClass({
              element: tabbedContent,
              bodyContainerClass: 'tabbed-content-body',
              navContainerClass: 'tabbed-content-nav',
            })
        );
      }
    );
  }
})();

(() => {
  // Collapsible Content
  const collapsibleContentElements = [...document.querySelectorAll('.collapsible-content')];
  if (collapsibleContentElements.length > 0) {
    import(/* webpackChunkName: "vanilla-collapsible-content" */ '@morsedigital/vanilla-collapsible-content').then(
      ({ default: CollapsibleContentClass }) => {
        collapsibleContentElements.forEach(
          collapsibleContent =>
            new CollapsibleContentClass({
              element: collapsibleContent,
              bodyContainerClass: 'collapsible-content-body',
              itemContainerClass: 'collapsible-content-item',
              toggleContainerClass: 'collapsible-content-toggle',
              expanded: true,
            })
        );

        global.CollapsibleContent = CollapsibleContentClass;
      }
    );
  }
  // End Collapsible Content
})();

// Async built into modules
(() => {
  DatePicker('.new-datepicker, .form__input--calender');
})();

(function() {
  VenueNav('venue-navigation');
})();

(() => {
  GeoLocation();
})();
