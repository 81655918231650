import { format, addDays, isAfter, isBefore, parse } from 'date-fns';
import Moment from 'moment';
import { Parser } from './index';

const START_POINT = 0; //Covid19 override. Was 30
const FINISH_POINT = 90;

export const setFormat = date => format(date, 'DD/MM/YYYY');

const setNewDate = (date, min, max) => {
  if (isBefore(date, min)) return min;
  if (isAfter(date, max)) return max;

  return date;
};

const setFinished = finishCal => date => {
  const finishedMax = addDays(date, FINISH_POINT);
  const finishedMin = addDays(date, 30);
  finishCal.setMinDate(finishedMin);
  finishCal.setMaxDate(finishedMax);

  const updatedDate = setNewDate(finishCal.getDate(), finishedMin, finishedMax);
  finishCal.setDate(updatedDate);
  finishCal.show();
};

export default (pickers, Pikaday) => {
  if (pickers.length === 0) return;

  const start = pickers.find(picker => picker.id === 'start_date');

  const finish = pickers.find(picker => picker.id === 'finish_date');

  if (finish == null) {
    const startPoint = addDays(new Date(), START_POINT);

    const startCal = new Pikaday({
      field: start,
      format: 'DD/MM/YYYY',
      minDate: startPoint,
      parse: Parser,
      toString: date => setFormat(date),
      onSelect: date => {
        start.value = setFormat(date);
        setter(date);
      },
    });

  } else {
    finish.disabled = true;
    const startPoint = addDays(new Date(), START_POINT);

    const finishCal = new Pikaday({
      field: finish,
      format: 'DD/MM/YYYY',
      parse: Parser,
      toString: date => setFormat(date),
      onSelect: date => {
        finish.value = setFormat(date);
      },
    });

    const setter = setFinished(finishCal);

    const startCal = new Pikaday({
      field: start,
      format: 'DD/MM/YYYY',
      minDate: startPoint,
      parse: Parser,
      toString: date => setFormat(date),
      onSelect: date => {
        start.value = setFormat(date);

        finish.disabled = false;
        setter(date);
      },
    });
  }
};
