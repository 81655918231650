import CookieMgmt from '@djforth/cookie_mgmt_fp';

import {
  AddClick,
  AddSelect,
  GetButtons,
  PersistData,
  ComposeClickHandler,
} from './accessibility_helpers';

// Apply Contrast to page
const ApplyContrast = (linkTag) => (target) => {
  const stylesheet = target.getAttribute('data-stylesheet');
  linkTag.setAttribute('href', stylesheet);
};

// Checks cookie and applies to linkTag if it exists
const checkCookie = (cookieName, linkTag) => {
  const contrastCookie = CookieMgmt(cookieName);
  // Applies css if available
  const path = contrastCookie.getValue();
  if (path && linkTag.getAttribute('href') !== path) {
    linkTag.setAttribute('href', path);
  }

  return contrastCookie;
};

const ColorContrast = (
  stylesheetId = 'application-css',
  cookieName = 'contrast_cookie'
) => {
  // Gets link tag to apply css
  const linkTag = document.getElementById(stylesheetId);
  if (!linkTag) return; // Bails if no linkTag
  const contrastCookie = checkCookie(cookieName, linkTag);
  // Gets buttons
  const contrastButtons = GetButtons('[data-contrast]');
  // Composes click handler
  const clickHandler = ComposeClickHandler([
    AddSelect(contrastButtons, 'contrast__button'),
    ApplyContrast(linkTag),
    PersistData(contrastCookie, 'data-stylesheet'),
  ]);

  contrastButtons.forEach((btn) => {
    AddClick(btn, clickHandler);
  });
};

export default ColorContrast;
