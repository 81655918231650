import { CheckIE, getLinkNo, GetSub } from '../helpers';

import { DESKTOP_SUB_CLASS, DESKTOP_SUB_HOLDER } from '../config';

const getSub = GetSub({ holderCSS: DESKTOP_SUB_HOLDER, subCSS: DESKTOP_SUB_CLASS });

const showSub = active => ({ id }) => {
  const sub = getSub(id);
  if (!sub) return;
  const act = active.includes(id);
  sub.setAttribute('aria-hidden', !act);
  sub.setAttribute('tab-index', act ? 0 : '-1');
};

export default watcher => {
  watcher(['active'], ({ active, navitems }) => {
    navitems.forEach(showSub(active));
  });
};
