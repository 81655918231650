import './questionnaire.scss';

export default () => {
  const imperial = document.getElementById(
    'questionnaire_response_answers_attributes_0_imperial_true'
  );
  const metric = document.getElementById(
    'questionnaire_response_answers_attributes_0_imperial_false'
  );
  const heightLarge = document.getElementById('bmi_height_large_units');
  const weightLarge = document.getElementById('bmi_weight_large_units');
  const heightSmall = document.getElementById('bmi_height');
  const weightSmall = document.getElementById('bmi_weight');
  const imperialHeightUnits = document.querySelector(
    '.questionnaire_response_answers_height_large_units'
  );
  const imperialWeightUnits = document.querySelector(
    '.questionnaire_response_answers_weight_large_units'
  );

  if (!imperial || !metric) return;

  imperial.addEventListener('click', () => {
    heightLarge.classList.remove('height');
    weightLarge.classList.remove('height');
    imperialHeightUnits.style.display = 'block';
    imperialWeightUnits.style.display = 'block';
    heightSmall.placeholder = 'Inches';
    weightSmall.placeholder = 'Pounds';
  });

  metric.addEventListener('click', () => {
    heightLarge.classList.add('height');
    weightLarge.classList.add('height');
    imperialHeightUnits.style.display = 'none';
    imperialWeightUnits.style.display = 'none';
    heightSmall.placeholder = 'Centimetres';
    weightSmall.placeholder = 'Kilograms';
  });

  [heightLarge, weightLarge, heightSmall].forEach(function(item) {
    item.addEventListener('keyup', () => {
      var txt = item.value;
      item.value = txt.replace(/[^0-9]+/, '');
    });
  });

  weightSmall.addEventListener('change', () => {
    if (weightSmall.placeholder === 'Pounds' && weightSmall.value >= 14) {
      weightSmall.value = '';
    }
  });
};
