export default () => {
    
  const shareBtn = document.querySelector('.news-share');

  let title
  let text
  let link = window.location.href

  if(!shareBtn) {return};
  
  shareBtn.addEventListener("click", () => {
    title = document.querySelector('.page-header > h1').textContent;
    text = document.querySelector('.tinymce > p').textContent;

    if(navigator.share){
      navigator.share({
        title: title,
        text: text,
        url: link,
      })
      .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'})
      .catch((error) => {console.log('Error share', error)});
    }else{
      navigator.clipboard.writeText(link).then(() => {
        shareBtn.querySelector('span').innerHTML = 'Link Copied'
      });
      console.log('copied');
    }
  }) 


}