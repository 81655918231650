// @flow
import {
  CHANGE_DEVICE,
  FETCH_DATA_FAIL,
  FETCH_DATA_SUCCESS,
  OPEN_NAV,
  RESET_ACTIVE,
  SET_ACTIVE,
} from '../actions/navitems';

const initState = {
  device: 'mobile',
  navitems: [],
  active: [],
  focus: '',
  open: true,
};

const manageActive = (state, { active: newActive, level }) => {
  const { active: oldActive } = state;
  let active;

  if (!newActive) {
    active = oldActive.slice(0, -1);
    return { ...state, active };
  }

  if (level !== null) {
    active = oldActive.slice(0);
    active[level] = newActive;
  } else {
    active = oldActive.concat([newActive]);
  }

  return { ...state, active };
};

const navitems = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_DEVICE:
      return { ...state, device: action.device };
    case OPEN_NAV:
      return { ...state, open: action.open, active: [] };
    case FETCH_DATA_FAIL:
      return { ...state, error: action.error };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        navitems: action.data,
      };
    case RESET_ACTIVE:
      return {
        ...state,
        active: [],
      };
    case SET_ACTIVE:
      return manageActive(state, action);
    default:
      return state;
  }
};

export default navitems;
