export default () => {
  var contactRequestedCheckbox = document.getElementById('questionnaire_response_contact_requested');
  var contactFields = document.getElementById('questionnaire-contact-fields');
  if(contactRequestedCheckbox === null) { return; }


  contactRequestedCheckbox.addEventListener('click', () => {
    var contactFieldIds = ['email', 'firstname', 'lastname', 'mobile', 'borough_id'];
    toggleContactFields(contactFieldIds);
  });

  function toggleContactFields(contactFieldIds){
    if(contactRequestedCheckbox.checked == true) {
      contactFields.style.display = 'block';
    } else {
      contactFields.style.display = 'none';
    }

    contactFieldIds.forEach(function(id){
      var fullId = 'questionnaire_response_' + id;
      var inputForId = document.getElementById(fullId);
      var labelForId = document.querySelector('label[for="'+ fullId +'"]');
      toggleRequired(inputForId);
      toggleRequired(labelForId);
      toggleRequiredLabel(labelForId);
    });
  }

  function toggleRequired(input) {
    if(input.classList.contains('optional')){
      input.classList.remove('optional');
      input.classList.add('required');
    } else if(input.classList.contains('required')){
      input.classList.add('optional');
      input.classList.remove('required');
    }
  }

  function toggleRequiredLabel(input){
    if(input.innerHTML.indexOf('Required') != -1){
      var txt = input.innerHTML;
      input.innerHTML = txt.replace(/<abbr title="Required">.<\/abbr>/, "");
    } else {
      input.innerHTML += '<abbr title="Required">*</abbr>';
    }
  }
};
