import './table.scss';

export default () => {
  const responsiveTables = [...document.querySelectorAll('.dynamic-panel.responsive-table')];

  if (responsiveTables.length > 0) {
    import(/* webpackChunkName: "responsive_table" */ './responsive_table').then(
      ({ default: ResponsiveTableClass }) => {
        responsiveTables.map(responsiveTable => new ResponsiveTableClass(responsiveTable));
      }
    );
  }
};
