export default () => {
  const input = document.querySelector('.slider');
  const output = document.getElementById('range-slider-output');
  const descriptor = document.getElementById('descriptor');

  if (!input) return;
  if (!descriptor) return;

  input.addEventListener('mouseup', () => {
    localStorage.setItem(input.id, input.value);
  });

  input.addEventListener('touchend', () => {
    localStorage.setItem(input.id, input.value);
    output.innerText = input.value + ' ' + descriptor.innerText;
  });

  input.addEventListener('mousemove', () => {
    output.innerText = input.value + ' ' + descriptor.innerText;
  });

  input.addEventListener('touchmove', () => {
    output.innerText = input.value + ' ' + descriptor.innerText;
  });

  const lsRangeValue = localStorage.getItem(input.id);

  if (lsRangeValue) {
    input.value = lsRangeValue;
    output.innerText = lsRangeValue + ' ' + descriptor.innerText;
  } else {
    input.value = input.value;
    output.innerText = input.value + ' ' + descriptor.innerText;
  }
};
