import { first } from "lodash";

export default () => {
  let photoCompForm = document.querySelector('#photo-competition-holder');
  let parentalFields = document.querySelector('.photo-competition-parent-access-option-fields');
  let eighteenCheck = document.querySelector('#contact_photography_competitions_application_applicants_attributes_0_under_18');
  let compCategory = document.querySelector('#contact_photography_competitions_application_applicants_attributes_0_competition_category');

  // let parentalEmail = document.querySelector('#contact_photography_competitions_application_applicants_attributes_0_email');
  // let parentalPhone = document.querySelector('#contact_photography_competitions_application_applicants_attributes_0_phone');
  // let parentalPost = document.querySelector('#contact_photography_competitions_application_applicants_attributes_0_postcode');
  // let mandatoryFields = [parentalEmail, parentalPhone, parentalPost];

  if(photoCompForm){
    eighteenCheck.addEventListener('change', function(e) {
      if(eighteenCheck.checked){
        parentalFields.classList.add('photo-comp-expanded')
        compCategory.value = 'under_18'
      }else{
        parentalFields.classList.remove('photo-comp-expanded')
        compCategory.value = 'over_18'
      }

      // mandatoryFields.forEach((el) => {
      //   el.classList.toggle('required');
      //   if(el.classList.contains('required')){
      //     el.setAttribute('required', 'required');
      //     el.setAttribute('aria-required', 'true');
      //   }else{
      //     el.removeAttribute('required');
      //     el.removeAttribute('aria-required');
      //   }
      // });   

    });
  }

};