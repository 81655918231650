export default () => {
  const enquiryReasonDropdown = document.getElementById('lesson_course_enquiry_reason_for_enquiry')
  const freezeReason = document.querySelector('.lesson_course_enquiry_reason_for_freeze')
  const freezeReasonDropdown = document.getElementById('lesson_course_enquiry_reason_for_freeze')
  const uploadProof = document.querySelector('.lesson_course_enquiry_uploaded_file_attachment')

  const reasonListener = () => {
    if(enquiryReasonDropdown.value == "Lesson Freeze"){
      freezeReason.style.display = "block";
    }else{
      freezeReason.style.display = "none";
      if(uploadProof){
      uploadProof.style.display = "none";
      }
    }
  }

  const freezeListener = () =>{
    if(freezeReasonDropdown.value == "Medical or injury related" || freezeReasonDropdown.value == "Pregnancy"){
      uploadProof.style.display = "block";
    }else{
      uploadProof.style.display = "none";
    }
  }

  if(enquiryReasonDropdown){
    enquiryReasonDropdown.onchange = reasonListener;
  };
  if(uploadProof){
    freezeReasonDropdown.onchange = freezeListener;
  };



  // CANCELLATIONS

  const reason = document.querySelector('#lesson_course_cancellation_reason');
  const movingLink = document.querySelector("[data-attr='location-link']");

  if(reason){
    movingLink.style.marginBottom = '20px';
    movingLink.style.display = 'none';
    movingLink.style.textAlign = 'center';

    reason.addEventListener('change', () => {
      if(reason.value == 'Moving Away'){
        movingLink.style.display = 'block';
      } else {
        movingLink.style.display = 'none';
      }
    })

  }



};
