export default () => {

  let reactTimetable = document.querySelector("[data-react-class='app.Timetable']")

  if(reactTimetable){
    
    let observer = new MutationObserver(function (mutationList, obsrvr) {      
      let todaysBtn = react.querySelector('.weekly-prog').querySelector('.date-list__item--today'); 
      if (todaysBtn) {
        todaysBtn.addEventListener('click', () => {
          console.log('test');
          location.reload();
          return false;
        })   
        obsrvr.disconnect(); // stop observing
        return;
      }      
    });
    
    let parentElement = document.querySelector(".wrap");
    
    observer.observe(parentElement, {
      childList: true,
      subtree: true,
    });
    
  }

}