export default () => {
  // const results = document.getElementById('results');
  const search = document.querySelector('.cl-book-activity-search');
  const input = document.querySelector('.cl-book-activity-search__input');
  const submit = document.getElementById('cl-book-activity-submit');

  if (search) {
    input.addEventListener('keydown', () => {
      submit.click();
    });
  }
};
