// Carousel
const carouselElements = Array.prototype.slice.call(document.querySelectorAll('.carousel'));

if (carouselElements.length > 0) {
  import(/* webpackChunkName: "vanilla-carousel" */ '@morsedigital/vanilla-carousel').then(
    ({ default: CarouselClass }) => {
      CarouselClass();
      console.log('Carousel test')
    }
  );

  import(/* webpackChunkName: "event_tracking_carousel" */ './event_tracking/carousel').then(
    ({ default: CarouselEventTrackingModule }) => {
      const CarouselEventTracking = CarouselEventTrackingModule();

      carouselElements.forEach(carousel => {
        CarouselEventTracking.init(carousel);
      });
    }
  );
}
// End Carousel
