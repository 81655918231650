import { getSection, getLinkNo, levelZero } from './helpers';

const baseElements = (nav, language) => `
  <div class="nav">
    <div class="nav-holder nav-holder--desktop">
      <ul class="desktop-nav__items desktop-nav__items--level0">
        ${nav}
      </ul>
      ${language || ''}
    </div>
    
  </div>
  
`;

const linkWrapperCss = level => {
  const css = `desktop-${getSection(level)}__item`;
  if (levelZero(level)) {
    return `${css} desktop-nav__item--root`;
  }

  return css;
};

const navLiCss = level => {
  const section = getSection(level);

  return `desktop-${section}__item desktop-${section}__item--level${level}`;
};

const navLinkCss = level => `desktop-${getSection(level)}__link`;

const navUlCss = (sub, level) => {
  const base = 'desktop-sub-nav__items';
  const extra = getLinkNo(sub) > 8 ? 'desktop-sub-nav__items--large' : '';
  return `${base} desktop-sub-nav__items--level${level} ${extra}`.trim();
};

const svgArrow = title => `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.18 8.51" class="desktop-nav__icon">
  <title>${title}</title>
  <path d="M1,1,5,4.5,1,8" transform="translate(-0.34 -0.25)" style="fill: none; stroke-miterlimit: 10; stroke-width: 1;"></path>
</svg>`;

const subButton = (id, title) => `
  <div class="desktop-nav__btn-wrapper">
    <button aria-pressed="false" class="desktop-nav__link" type="button" id="${id}">
      ${title} ${svgArrow(title)}
    </button>
  </div>
`;

const subTitle = (id, title) => `
  <h3 class="desktop-sub-nav__title" id="${id}">${title}</h3>
`;

const subLink = ({ id, path, title }, level) => `
  <li class="${linkWrapperCss(level)}">
    <a href="${path}" class="${navLinkCss(level)}" id="${id}">${title}</a>
  </li>
`;

const subSection = ({ id, sub, title }, level) => `
  <li class="${navLiCss(level)}">
    ${level === 0 ? subButton(id, title) : subTitle(id, title)}
    <div class="sub-nav sub-nav--level${level}" aria-hidden="true">
      <ul class="${navUlCss(sub, level + 1)}">
        ${sub
          .map(item => {
            if (item.sub) {
              return subSection(item, level + 1);
            }

            return subLink(item, level + 1);
          })
          .join('')}
      </ul>
    </div>
  </li>
`;

export default ({ navitems }) => {
  const nav = document.getElementById('primary-nav');
  if (!nav) return;

  const languageChooser = nav.dataset.languageChooser ? JSON.parse(nav.dataset.languageChooser) : '';

  const template = navitems
    .map(item => {
      if (item.sub) {
        return subSection(item, 0);
      }

      return subLink(item, 0);
    })
    .join('');

  nav.innerHTML = baseElements(template, languageChooser);
};
