const getActiveItems = (active, items) =>
  active.reduce((act, activeId) => {
    if (act.length === 0) {
      const item = items.findIndex(({ id }) => id === activeId);

      return act.concat([item]);
    }
    const top = items[act[0]];
    if (top.sub) {
      const item = top.sub.findIndex(({ id }) => id === activeId);
      return act.concat([item]);
    }

    return act;
  }, []);

const mergeItems = items =>
  items.map(item => {
    if (item.sub) {
      const sub = item.sub.reduce((s, it) => {
        if (it.sub) {
          return s.concat(it.sub);
        }

        return s;
      }, item.sub.filter(i => !i.sub));
      return { ...item, sub };
    }
    return item;
  });

const manageIndexes = (act, items) => {
  const activeIndexes = getActiveItems(act, items);
  return {
    current: level => activeIndexes[level],
    decrease: (level, amount = 1) => {
      const active = activeIndexes[level];
      const newActive = active - amount;
      return newActive <= 0 ? 0 : newActive;
    },
    increase: (level, amount = 1) => {
      const active = activeIndexes[level];
      const length = (level === 0 ? items.length : items[activeIndexes[0]].sub.length) - 1;
      const newActive = active + amount;
      return newActive >= length ? length : newActive;
    },
    length: () => activeIndexes.length,
  };
};

const GoHorizontal = (items, indexes) => dir => {
  if (indexes.length() === 1) {
    const next = dir === 'left' ? indexes.decrease(0) : indexes.increase(0);
    return items[next].id;
  }
  const top = items[indexes.current(0)];
  if (!top) return null;

  if (top && top.sub && top.sub.length > 8) {
    const next = dir === 'left' ? indexes.decrease(1) : indexes.increase(1);
    return top.sub[next].id;
  }

  if (!top.sub) {
    return top[indexes.current(0)].id;
  }

  return top.sub[indexes.current(1)].id;
};

const getPosition = (sub, indexes, dir) => {
  const amount = sub.length > 8 ? 3 : 1;
  return dir === 'up' ? indexes.decrease(1, amount) : indexes.increase(1, amount);
};

const checkUp = (pos, indexes, sub) => {
  const amount = sub.length > 8 ? 3 : 0;
  return pos === indexes.current(1) || indexes.current(1) <= amount;
};

const GoVertical = (items, indexes) => dir => {
  const top = items[indexes.current(0)];

  if (indexes.length() === 1) {
    if (dir === 'up') {
      return { id: top.id, level: 0 };
    }

    return top.sub ? { id: top.sub[0].id, level: 1 } : { id: top.id, level: 0 };
  }
  const pos = getPosition(top.sub, indexes, dir);

  if (dir === 'up' && checkUp(pos, indexes, top.sub)) {
    return { id: top.id, level: 0 };
  }
  return { id: top.sub[pos].id, level: 1 };
};

export default items => (active, dir) => {
  const mergedItems = mergeItems(items);
  const activeIndexes = manageIndexes(active, mergedItems);
  const goHorizontal = GoHorizontal(mergedItems, activeIndexes);
  const goVertical = GoVertical(mergedItems, activeIndexes);
  switch (dir) {
    case 'down':
      return goVertical('down');
    case 'left':
      return { id: goHorizontal('left'), level: active.length - 1 };

    case 'right':
      return { id: goHorizontal('right'), level: active.length - 1 };
    case 'up':
      return goVertical('up');
    default:
  }
};
