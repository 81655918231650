// import tabbable from 'tabbable';
import { defer } from 'lodash';
import { getElement } from '../helpers';
import TrackPosition from '../helpers/track_position';

import { MAIN_NAV } from '../config';

const TrackFocused = (dispatch, tracker) => (active, dir) => {
  const { id } = tracker(active, dir);
  // console.log('focus', id);
  const el = getElement(id);
  if (el) {
    defer(() => {
      el.setAttribute('aria-pressed', 'true');
      el.focus();
    });
  }
};

const setKeyHandler = (tracker, nav) => e => {
  /* eslint-disable default-case */
  const { active } = nav;
  switch (e.keyCode) {
    case 37: // left arrow
      tracker(active, 'left');
      break;
    case 39: // Right arrow
      tracker(active, 'right');
      break;
    case 38:
      e.preventDefault();
      tracker(active, 'up');
      break;
    case 40:
      e.preventDefault();
      tracker(active, 'down');
      break;
  }
  /* eslint-enable default-case */
};

const setKeyEvent = (nav, handler) => {
  if (nav.handler) {
    nav.removeEventListener('keydown', nav.handler, true);
  }
  nav.handler = handler;
  nav.addEventListener('keydown', handler, true);
};

export default ({ dispatch, getState }, watcher) => {
  const { active, navitems } = getState();
  const nav = getElement(MAIN_NAV);
  let tracker = TrackFocused(dispatch, TrackPosition(navitems));
  nav.active = active;
  setKeyEvent(nav, setKeyHandler(tracker, nav));

  watcher(['active'], ({ active: act }) => {
    nav.active = act;
  });

  watcher(['navitems'], ({ active: act, navitems: ni }) => {
    nav.active = act;
    tracker = TrackFocused(dispatch, TrackPosition(ni));
    setKeyEvent(nav, setKeyHandler(tracker, nav));
  });
};
