import Hamburger from './hamburger';
import OpenNav from './open_nav';
import RootBtns from './root_buttons';
import SetSubs from './set_subs';
import ShowSubs from './show_sub';

export default (store, watcher) => {
  Hamburger(store, watcher);
  OpenNav(watcher);
  RootBtns(store, watcher);
  SetSubs(store, watcher);
  ShowSubs(watcher);
};
