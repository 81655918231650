import { getAttribute, getElement, SetRoots } from '../helpers';

import { SetActive, ResetActive } from '../actions/navitems';

const setActive = (active = []) => ({ id }) => {
  if (!active.includes(id)) {
    const item = getElement(id);
    if (!item) return;

    item.setAttribute('aria-pressed', 'false');
  }
};

const ClickHandler = (dispatch, action) => e => {
  e.preventDefault();
  e.target.setAttribute('aria-pressed', 'true');
  clearTimeout(e.target.timeoutID);
  dispatch(ResetActive());
  dispatch(action(e.target.id, 0));
};

export const OutHandler = (dispatch, action) => e => {
  e.preventDefault();
  e.target.timeoutID = setTimeout(() => {
    dispatch(action(null, 0));
    e.target.setAttribute('aria-pressed', 'false');
  }, 50);
};

const selectRoot = ({ navitems, active }) => {
  navitems.forEach(setActive(active));
};

const SetHover = dispatch => {
  const inHandler = SetRoots(ClickHandler(dispatch, SetActive), 'mouseenter');
  const focusHandler = SetRoots(ClickHandler(dispatch, SetActive), 'focus');
  const outHandler = SetRoots(OutHandler(dispatch, SetActive), 'mouseleave');

  return item => {
    inHandler(item);
    outHandler(item);
    focusHandler(item);
  };
};

export default ({ dispatch, getState }, watcher) => {
  const { device: dev, navitems: nis } = getState();
  const handlers = SetHover(dispatch, SetActive);
  if (nis && dev !== 'mobile') {
    nis.forEach(handlers);
  }

  watcher(['device', 'navitems'], ({ device, navitems }) => {
    if (device === 'mobile') return;
    if (!navitems) return;
    navitems.forEach(handlers);
  });

  watcher(['active'], selectRoot);
};
