import { differenceInMinutes, isValid } from 'date-fns';
import { FETCH_DATA, FETCH_DATA_FAIL, FETCH_DATA_SUCCESS } from '../actions/navitems';

import { handleError } from '../../../components/fetch_helpers/post_helpers';

import FetchData from './helpers/fetch_data';

import LocalData from './helpers/local_data';

import ResetNav from './helpers/reset_nav';

import Processor from '../database/processors';

// Checks if it should update
const checkUpdate = async db => {
  if ('msIndexedDB' in window) return true;
  try {
    const updated = await db.get('updated', 'Better-Nav');

    const updateDate = new Date(updated);
    if (!isValid(updateDate)) return true;
    return differenceInMinutes(new Date(), updateDate) > 5;
  } catch (e) {
    return true;
  }
};

export default db => ({ dispatch, getState }) => next => async action => {
  if (action.type === FETCH_DATA) {
    const { url } = getState();
    const processor = Processor(db);

    // Error message helper
    const errorMessage = handleError(next, FETCH_DATA_FAIL);
    let response;
    try {
      const update = await checkUpdate(db);
      // Fetch data
      response = await (update ? FetchData(url) : LocalData(db));
      // Checks if successful
      if (response.isSuccess) {
        // Gets and process json
        const { data } = response;

        // Adds to indexDB
        await processor(data);
        // Sends to reducer
        return next({
          data,
          type: FETCH_DATA_SUCCESS,
        });
      }
      // Failure
      return errorMessage(response.error);
    } catch (e) {
      // Failure
      return errorMessage(e);
    }
  }

  ResetNav(dispatch, action);

  return next(action);
};
