import Curry from '../../helpers/curry';

import StoreOptions from './store_options';

import BuildOptions from './build_options';

const setFilter = el => {
  const select = document.getElementById(el.dataset.filter);
  const store = StoreOptions(select);
  const builder = Curry(BuildOptions, select);

  el.addEventListener(
    'change',
    e => {
      builder(store(e.target.value));
    },
    false
  );
};

export default () => {
  const filters = [...document.querySelectorAll('[data-filter]')];

  if (filters.length === 0) return;

  filters.forEach(setFilter);
};
