export default () => {

  window.libInfo = (btn) => {
    let descr = btn.closest('.weekly-timetable__sessions--body').nextElementSibling
    let notes = btn.closest('.weekly-timetable__sessions--body').nextElementSibling.nextElementSibling
    let elements = [btn, descr, notes]

    elements.forEach(el => {
      el.classList.toggle('open');
    });
  };

};