import '../../images/logo.svg';

import Store from './store';
import Manager from '../manage_events';

import tracker from '../tracker';
import WatchChanges from './helpers/watch_changes';

import { MAIN_NAV } from './config';

import { ChangeDevice, FetchData } from './actions/navitems';

import Desktop from './desktop';
import Format from './format';
import Mobile from './mobile';
import Search from './search';
import Setup from './setup';

// Closest & matches polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export default async () => {
  const NavEl = document.getElementById(MAIN_NAV);

  if (!NavEl) return; // Stops if no nav

  Setup();

  const store = await Store({ ...NavEl.dataset }); // Sets redux

  const watcher = WatchChanges(store); // Watch for changes
  const { dispatch } = store;
  dispatch(FetchData()); // Gets nav json
  // // Sets up HTML
  Format(store, watcher);
  // Sets mobile function
  Mobile(store, watcher);
  // Sets Desktop Actions
  Desktop(store, watcher);
  // Sets Search Actions
  Search(store, watcher);
  // // Tracks device
  tracker(dev => {
    // console.log('dev', dev);
    dispatch(ChangeDevice(dev));
    NavEl.dataset.device = dev;
  });
};
