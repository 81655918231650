export default () => {
  const carousel = document.getElementById('main-carousel');
  const singleCarousel = document.querySelector('.carousel__item--single');
  const activityBanner = document.querySelector('.carousel');
  // const carouselItem = document.querySelector('.carousel__item');

  if (carousel) {
    carousel.style.visibility = 'visible';
  }

  if (activityBanner) {
    activityBanner.style.visibility = 'visible';
  }

  if (singleCarousel) {
    activityBanner.style.visibility = 'visible';
  }
};
