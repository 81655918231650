import { getElement } from '../helpers';

import { MOBILE_SUB_CLASS, MOBILE_SUB_HOLDER, MOBILE_BACK_CLASS } from '../config';

// const getSub = GetSub({ holderCSS: MOBILE_SUB_HOLDER, subCSS: MOBILE_SUB_CLASS });

const setBack = (sub, active) => {
  const back = sub.querySelector(MOBILE_BACK_CLASS);
  if (!back) return;

  back.setAttribute('aria-hidden', !active);
  back.disabled = !active;
};

const hideLink = (sub, active) => {
  const mainLink = sub.parentNode.querySelector('button');
  if (!mainLink) return;

  mainLink.setAttribute('aria-hidden', active);
};

const GetSubSection = id => {
  const btn = getElement(id);
  if (!btn) return null;
  const subSection = btn.nextElementSibling;
  if (subSection && subSection.nodeName === 'UL') {
    return subSection;
  }

  return null;
};

const ShowSubSection = active => ({ id, sub }) => {
  const subSection = GetSubSection(id);
  if (!subSection) return null;
  const act = active.includes(id);
  subSection.setAttribute('aria-hidden', !act);
  subSection.setAttribute('tab-index', act ? 0 : -1);
  setBack(subSection, active.slice(-1)[0] === id);
  hideLink(subSection, act);

  if (sub) {
    sub.forEach(ShowSubSection(active));
  }
};

const showSubs = ({ active, navitems, device }) => {
  if (device !== 'mobile') return;
  navitems.forEach(ShowSubSection(active));
};

export default watcher => {
  watcher(['active'], showSubs);
};
