import { DESKTOP_SUB_CLASS } from '../config';

import { getElements } from '../helpers';

export default () => {
  const desktopElms = getElements(DESKTOP_SUB_CLASS);

  if (desktopElms.length > 0) {
    desktopElms.forEach(elm => {
      elm.setAttribute('aria-hidden', 'true');
    });
  }
};
