import DatePicker from '../datepicker';

export default () => {

  let lvPartyForm = document.querySelector("#new_contact_lee_valley_park_birthday_party_enquiry_form")

  if(lvPartyForm){    

    let observer = new MutationObserver(function (mutationList, obsrvr) {            
      let partyDateField = document.querySelector('#contact_lee_valley_park_birthday_party_enquiry_form_date'); 
      let mealElements = document.querySelector('.party-dietry-field');
      let cateringTrue = document.querySelector('#contact_lee_valley_park_birthday_party_enquiry_form_catering_required_true');
      let cateringFalse = document.querySelector('#contact_lee_valley_park_birthday_party_enquiry_form_catering_required_false');
      
      if (partyDateField) {        
        (() => {
          DatePicker('.new-datepicker, .form__input--calender');
        })();               
        obsrvr.disconnect(); // stop observing        
      }

      if(mealElements){
        mealElements.style.display = 'none'        
        cateringTrue.addEventListener('click', () => {
          mealElements.style.display = 'block'
        })        
        cateringFalse.addEventListener('click', () => {
          mealElements.style.display = 'none'
        })
      }

    });
    
    let parentElement = document.querySelector(".wrap");    
    observer.observe(parentElement, {
      childList: true,
      subtree: true,
    });

    
  }

};