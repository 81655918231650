let events = [];

const findInList = (list, el, ev) => list.find(({ element, event }) => el === element && ev === event);

export default {
  addEventListener: (element, event, listener, capture = false) => {
    const item = findInList(events, element, event);
    if (item) {
      return item;
    }

    events.push({
      element,
      event,
      listener,
    });

    element.addEventListener(event, listener, capture);
  },
  flush: () => {
    events = events.filter(({ el }) => document.body.contains(el));
  },
  removeEventListener: (el, ev) => {
    const item = findInList(events, el, ev);

    if (!item) return false;

    const { element, event, listener } = item;

    element.removeEventListener(event, listener);
  },
};
