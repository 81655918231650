export default () => {
  const btn = document.querySelector('[data-geo]');

  if (!btn) return;
  if("geolocation" in navigator) {
    import(/* webpackChunkName: "geo-location" */ './geo').then(({ default: Geo }) => {
      Geo(btn);
    });
  } else {
    btn.style.display = "none";
  }
  
};
