export const lazyPolyfill = () => {
  import(
    /* webpackChunkName: "lazyload-polyfill" */ '@djforth/lazyload-polyfill'
  ).then(({ default: lazyload }) => {
    lazyload();
  });
};

export const lozadLoad = () => {
  import(/* webpackChunkName: "lozad" */ 'lozad').then(({ default: lozad }) => {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
  });
};
