function isNumeric(str) {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function containsDot(str) {
  var dot_regex = new RegExp('/.');
  return (dot_regex.test(str) || multipleDots(str));
}

function multipleDots(str) {
  return str.split('.').length > 1;
}

function pennies(str) {
  var arr = str.split('.');
  return arr[arr.length - 1];
}

export default () => {
  const input = document.getElementById('refund_form_refund_value');

  if (input === null) { return; }

  input.addEventListener('keydown', (event) => {
    var key = event.key;
    var value = input.value;

    if (key === 'Backspace') {
      return;
    }

    event.preventDefault();

    if (value.length > 7 || (!isNumeric(key) && !(key === '.')) || key === '£') {
      return;
    }

    var charsBeforeDot = value.split('.')[(value.split('.').length - 1)];
    if ((charsBeforeDot.length > 3) && (isNumeric(key))) { return; }

    if (containsDot(value)) {
      if (multipleDots(value)) {
        if(key === '.') { return; }
      }

      var pence = pennies(value);

      if (pence.length > 1) {
        var original = '.' + pence;
        var trimmed = '.' + pence[0] + pence[1];
        value = value.replace(original, trimmed);
        return;
      }
    };


    const valueWithoutCurrency = value.replace(/[£]/g, '');
    const valueUnformatted = `${valueWithoutCurrency}${key}`
      .replace(/[^0-9\.]+/g, '');

    // console.log(valueUnformatted);

    var v = valueUnformatted;

    // var v = Number(valueUnformatted).toLocaleString('en-GB', {
    //   style: 'currency',
    //   currency: 'GBP',
    // });
    //
    // console.log(v);
    input.value = v;
  });
};
