import 'picturefill';
import svg4everybody from 'svg4everybody';
import { lazyPolyfill, lozadLoad } from './lazyloading';
import 'indexeddb-getall-shim';

// Load pollyfills

// import 'core-js/modules/es6.promise';

// Observer polyfill used by lazy loader
const loadObserverPolyfill = () => import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer');

/* eslint-disable */
const objectAssign = () => {
  if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
      enumerable: false,
      configurable: true,
      writable: true,
      value(target) {
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert first argument to object');
        }

        const to = Object(target);
        for (let i = 1; i < arguments.length; i++) {
          let nextSource = arguments[i];
          if (nextSource === undefined || nextSource === null) {
            continue;
          }
          nextSource = Object(nextSource);

          const keysArray = Object.keys(Object(nextSource));
          for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
            const nextKey = keysArray[nextIndex];
            const desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
            if (desc !== undefined && desc.enumerable) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
        return to;
      },
    });
  }
};
// eslint-enable

// IE11 polifill for ParentNode.append() required for lozad
(function(arr) {
  arr.forEach(item => {
    if (item.hasOwnProperty('append')) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        const argArr = Array.prototype.slice.call(arguments);
        const docFrag = document.createDocumentFragment();

        argArr.forEach(argItem => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      },
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);



// Load pollyfills
(function() {
  svg4everybody(); // SVG Pollyfill for external SVG's
  objectAssign(); // Fixes object assign
  // LazyLoading(); // Lazyload Pollyfill - sets to native if available
  // Loads Fetch pollyfill for AJAX requests
  const loadFetchPollyFill = () => import(/* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch');

  if (!('fetch' in window)) {
    loadFetchPollyFill();
  }

  
  if ('loading' in HTMLImageElement.prototype) {
    lazyPolyfill(); // Sets up for native lazyload
  } else {
    lozadLoad(); // Sets up for lozad lazyloading
  }

  if (!('IntersectionObserver' in window)) {
    loadObserverPolyfill();
  }
})();