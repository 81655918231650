export default () => {
  var refundFormMember = document.getElementById('refund_form_member');
  var wrapper = document.getElementById('refund_form_membership_number_wrapper');

  if(
    (wrapper === null) || 
    (refundFormMember === null) ||
    (typeof(wrapper) === 'undefined') ||
    (typeof(refundFormMember) === 'undefined')
  ) { return; }

  refundFormMember.onchange = () => {
    var display = 'block';
    var label = wrapper.querySelector('label');
    var input = wrapper.querySelector('input');
    if (refundFormMember.value != 'true') {
      display = 'none';
    }
    wrapper.style.display = display;
    toggleRequired(wrapper);
    toggleRequired(label);
    toggleRequiredLabel(label);
    toggleRequired(input);
  }

  function toggleRequired(input) {
    if(input.classList.contains('optional')){
      input.classList.remove('optional');
      input.classList.add('required');
      input.setAttribute('aria-required', 'required');
      input.setAttribute('required', 'required');
    } else if(input.classList.contains('required')){
      input.classList.add('optional');
      input.classList.remove('required');
      input.removeAttribute('aria-required');
      input.removeAttribute('required');
    }
  }

  function toggleRequiredLabel(input){
    if(input.innerHTML.indexOf('required') != -1){
      var txt = input.innerHTML;
      input.innerHTML = txt.replace(/<abbr title="required">.<\/abbr>/, "");
    } else {
      input.innerHTML += '<abbr title="required">*</abbr>';
    }
  }
};
