import { env } from 'process';

const createStore = schema => upgradeDB => {
  /* istanbul ignore else  */
  if (!upgradeDB.objectStoreNames.contains('updated')) {
    // @Terry disabling caching
    // upgradeDB.createObjectStore('updated');
  }
  schema.forEach(({ store }) => {
    /* istanbul ignore else  */
    if (!upgradeDB.objectStoreNames.contains(store)) {
      // @Terry disabling caching
      // upgradeDB.createObjectStore(store);
    }
  });
};

export default async (storeName, schema, version) => {
  /* istanbul ignore if  */
  if (!window.indexedDB) return Promise.resolve(null);
  /* istanbul ignore next */
  let idb = await import(/* webpackChunkName: "idb" */ 'idb');
  /* istanbul ignore next  */
  if (env.NODE_ENV === 'test') {
    idb = idb.default;
  }

  const dbPromise = idb.open(`${storeName}-store`, version, createStore(schema, storeName)).catch(err => {
    console.error('error', err);
  });

  return {
    get(store, key) {
      return dbPromise
        .then(db => {
          const tx = db.transaction(store);
          const st = tx.objectStore(store);
          return st.get(key);
        })
        .catch(err => {
          console.log('get', err);
        });
    },
    getAll(objStore) {
      return dbPromise
        .then(db => {
          const tx = db.transaction(objStore);
          const store = tx.objectStore(objStore);
          return store.getAll();
        })
        .catch(err => {
          console.log('getAll', err);
        });
    },
    set(objStore, key, val) {
      return dbPromise
        .then(db => {
          const tx = db.transaction(objStore, 'readwrite');
          const st = tx.objectStore(objStore);
          st.put(val, key);
          return tx.complete;
        })
        .catch(err => {
          console.log('set', err);
        });
    },
    delete(objStore, key) {
      return dbPromise
        .then(db => {
          const tx = db.transaction(objStore, 'readwrite');
          tx.objectStore(objStore).delete(key);
          return tx.complete;
        })
        .catch(err => {
          console.log('delete', err);
        });
    },
    clear(objStore) {
      return dbPromise.then(db => {
        const tx = db.transaction(objStore, 'readwrite');
        tx.objectStore(objStore).clear();
        return tx.complete;
      });
    },
    keys(objStore) {
      return dbPromise
        .then(db => {
          const tx = db.transaction(objStore);
          const keys = [];
          const store = tx.objectStore(objStore);

          // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
          // openKeyCursor isn't supported by Safari, so we fall back
          (store.iterateKeyCursor || store.iterateCursor).call(store, cursor => {
            if (!cursor) return;
            keys.push(cursor.key);
            cursor.continue();
          });

          return tx.complete.then(() => keys);
        })
        .catch(err => {
          console.log(err);
        });
    },
  };
};
