import fetchData from '../../fetch_data';

const stores = ['navitems'];

const createStoreName = ({ title }) => title.toLowerCase().replace(/\s/, '-');

export const createSchema = () => stores.map(store => ({ store, keyPath: 'key' }));

export default async () => {
  const db = await fetchData('Better-Nav', createSchema(), 1);

  // const setSessions = SetSessions(db, currentStore);
  // const sessions = processSessions(items);
  // if (sessions.length > 0) {
  //   await setSessions(sessions, sessions[0].start);
  // }

  return {
    db,
  };
};
