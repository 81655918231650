export default () => {

  const videoBar = document.querySelector('.video-bar');
  const openBtn = document.querySelector('.video-bar__btn');
  const modal = document.querySelector('.video-bar__modal');
  const alertBar = document.querySelector('.alerts-holder');
  
  const offsetShift = () => {
    let offset = alertBar.offsetHeight + 131 + 'px';
    videoBar.style.top = offset;
  }
  
  if(alertBar){
    offsetShift();
    alertBar.addEventListener("click", () => {
      offsetShift();
    })
  }


  const Player = document.getElementById('player2');
  let times = 0, playY;
  let currentPage = window.location.pathname;
  if (currentPage == '/' && videoBar){
    videoBar.style.display = "block";
    openBtn.addEventListener("click", () => {
      modal.classList.remove('hidden');
      if(times == 0){
        playY = Player.src += '?autoplay=1';
        times = 1;
      }
    })
    modal.addEventListener('click', (e) => {
      if(e.target.className == 'video-bar__modal'){
        modal.classList.add('hidden');
        playY = playY.slice(0, -11);
        Player.src = playY
        times = 0;
      }
    })
  };


};