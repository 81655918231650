const buildOptions = opt => `<option value='${opt.get('value')}'>${opt.get('text')}</option>`;

const buildOptGroup = optgroup => `<optgroup label='${optgroup.get('label')}'>
  ${optgroup.get('children').map(buildOptions)}
</optgroup>`;

const setType = opts => (opts.get('type') === 'optgroup' ? buildOptGroup : buildOptions);

export default (select, options) => {
  select.innerHTML = options.map(opts => setType(opts)(opts));
};
