const SetNavItems = (db, items) => items.map((item, i) => db.set('navitems', i, item));

export default db => async items =>
  Promise.all([SetNavItems(db, items), db.set('updated', 'Better-Nav', new Date().toJSON())]);

// export const GetSessions = (db) => async date => {
//   try {
//     const navitems = await db.get('navitems', '');
//     return navitems;
//   } catch (e) {
//     return null;
//   }
// };
