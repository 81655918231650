import { isSuccess as successCheck } from '../../../../components/fetch_helpers/post_helpers';

import processList from './process_items';

const getData = async url => {
  let response;
  try {
    response = await fetch(url);
  } catch (error) {
    return { error, isSuccess: false };
  }

  const isSuccess = successCheck(response);
  if (!isSuccess) return { error: response, isSuccess };

  const preData = await response.json();

  return { preData, isSuccess };
};

export default async url => {
  const fetcher = await getData(url);

  if (fetcher.isSuccess && fetcher.preData) {
    const data = processList(fetcher.preData);
    // Sends to reducer
    return { ...fetcher, data };
  }

  return fetcher;
};
