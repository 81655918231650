import { MOBILE_HOLDER_ID, MOBILE_WRAPPER_ID, SEARCH_BUTTON_ID, SEARCH_ID } from '../config';

import { getElement } from '../helpers';

import getHeight from '../helpers/get_height';

const removeHeight = wrapper => e => {
  e.target.removeEventListener('transitionend', e.target.transitionend, true);
  wrapper.style.height = 0;
};

const GetWrappers = () => {
  let wrapper = getElement(MOBILE_WRAPPER_ID);
  let holder = getElement(MOBILE_HOLDER_ID);

  return () => {
    if (!document.body.contains(holder)) {
      holder = getElement(MOBILE_HOLDER_ID);
    }

    if (!document.body.contains(wrapper)) {
      wrapper = getElement(MOBILE_WRAPPER_ID);
    }

    return {
      holder,
      wrapper,
    };
  };
};

const openMenu = () => {
  const getWrappers = GetWrappers();
  const { holder: h, wrapper: w } = getWrappers();

  if (!w || !h) return;

  w.style.height = 0;
  h.style.transform = `translateY(-100%)`;
  h.style.WebkitTransform = `translateY(-100%)`;

  const transitionend = removeHeight(w);
  h.transitionend = transitionend;

  return open => {
    const trans = open ? 0 : -100;
    const { holder, wrapper } = getWrappers();
    holder.style.transform = `translateY(${trans}%)`;
    holder.style.WebkitTransform = `translateY(${trans}%)`;

    if (open) {
      wrapper.style.height = `${getHeight(holder)}px`;
    } else {
      holder.addEventListener('transitionend', transitionend, true);
    }
  };
};

export default watcher => {
  let opener = openMenu();
  // const search = closeSearch();
  watcher(['device', 'navitems'], () => {
    opener = openMenu();
  });

  watcher(['open'], ({ open }) => {
    opener(open);
  });

  watcher(['active'], ({ open }) => {
    if (open) {
      const wrapper = getElement(MOBILE_WRAPPER_ID);
      const holder = getElement(MOBILE_HOLDER_ID);
      setTimeout(() => {
        wrapper.style.height = `${getHeight(holder)}px`;
      }, 30);
    }
  });
};
