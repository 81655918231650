import './stylesheets/venue_nav.scss';

export default id => {
  const venueNav = document.getElementById(id);
  if (venueNav) {
    import(/* webpackChunkName: "scroll-nav" */ '@morsedigital/scroll-nav').then(({ default: ScrollNav }) => {
      ScrollNav(venueNav);
    });
  }
};
