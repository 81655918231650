export default () => {
  const icons = [...document.querySelectorAll('.svg-icon')];
  const iconText = [...document.querySelectorAll('.icon-text')];

  icons.forEach((icon) => {
    if (icon.classList.contains('temporarily-unavailable')) {
      iconText.forEach((text) => {
        text.classList.add('temporarily-unavailable-copy');
      });
    }
  });
};
