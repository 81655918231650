export default () => {
  const ability = document.getElementById('swim_school_adviser_ability');
  const abilityClass = document.querySelector('.ability-class');
  const abilityClassCopy = document.querySelector('.ability-class__copy');
  const age = document.getElementById('swim_school_adviser_age');
  const checkbox = document.getElementById('swim_school_adviser_send_copy');
  const email = document.querySelector('.email');
  const inputs = [...document.querySelectorAll('.solve-buttons')];
  const over5 = document.querySelector('.over-5');
  const solved = document.querySelector('.enquiry-info');
  const submit = document.getElementById('swim-school-adviser-submit');
  const under3 = document.querySelector('.under-3');
  const under5 = document.querySelector('.under-5');

  if (age) {
    age.addEventListener('change', (event) => {
      if (event.target.value === 'under_3') {
        under3.classList.remove('hidden');
        over5.classList.add('hidden');
        abilityClass.classList.add('hidden');
        under5.classList.add('hidden');
      }

      if (event.target.value === 'under_5') {
        under5.classList.remove('hidden');
        over5.classList.add('hidden');
        abilityClass.classList.add('hidden');
        under3.classList.add('hidden');
      }

      if (event.target.value === 'over_5') {
        over5.classList.remove('hidden');
        under5.classList.add('hidden');
        under3.classList.add('hidden');
        abilityClass.classList.remove('hidden');
      }
    });
  }

  if (ability) {
    ability.addEventListener('change', (event) => {
      if (event.target.value === 'true') {
        abilityClass.classList.add('hidden');
      } else {
        abilityClass.classList.remove('hidden');
      }

      if (ability.value === 'non_swimmer') {
        abilityClassCopy.innerHTML = 'Foundation';
      }

      if (ability.value === 'improver') {
        abilityClassCopy.innerHTML = 'Development';
      }

      if (ability.value === 'advanced') {
        abilityClassCopy.innerHTML = 'Academy';
      }
    });
  }

  if (inputs) {
    inputs.forEach((input) => {
      if(input) {
        input.addEventListener('change', (event) => {
          if (event.target.value === 'true') {
            solved.classList.add('hidden');
          } else {
            solved.classList.remove('hidden');
          }
        });
      }
    });
  }

  if(checkbox) {
    checkbox.addEventListener('change', () => {
      if (checkbox.checked) {
        email.classList.remove('hidden');
      } else {
        email.classList.add('hidden');
      }
    });
  }

  if (submit) {
    submit.addEventListener('click', () => {
      submit.setAttribute('name', 'user_submit');
    });
  }
};
