// import { setUpdated } from './manage_updated';

export default (manager, updateKey) => async (data, processData, attrs) => {
  try {
    await Promise.all(
      Object.entries(processData).map(([storeName, processor]) => processor(manager, data, storeName, attrs))
    );
    await manager.set('updated', updateKey, new Date().getTime());
  } catch (e) {
    console.error('Failed to update', e);
  }
};
