import Manager from '../../manage_events';
import FormatDesktop from './desktop';
import FormatMobile from './mobile';

import { MAIN_NAV } from '../config';

const CheckMobile = nav => (device, force) => {
  if (force) return device === 'mobile';
  return nav.dataset.device !== 'mobile' && device === 'mobile';
};

const CheckDesktop = nav => (device, force) => {
  if (force) return ['tablet', 'desktop'].includes(device);
  return !['tablet', 'desktop'].includes(nav.dataset.device) && ['tablet', 'desktop'].includes(device);
};

const FormatNav = formatters => (state, force = false) => {
  const { device } = state;
  formatters.forEach(({ check, format }) => {
    if (check(device, force)) {
      format(state);
      Manager.flush();
    }
  });
};

export default ({ getState }, watcher) => {
  const NavEl = document.getElementById(MAIN_NAV);
  const formatter = FormatNav([
    { format: FormatMobile, check: CheckMobile(NavEl) },
    { format: FormatDesktop, check: CheckDesktop(NavEl) },
  ]);

  formatter(getState(), true);

  watcher(['device'], formatter);

  watcher(['navitems'], state => {
    NavEl.innerHTML = '';
    Manager.flush();
    formatter(state, true);
  });
};
