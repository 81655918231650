import './stylesheets/index.scss';
import CookieMgmt from '@djforth/cookie_mgmt_fp';
import { isElement } from '../helpers/check_elements';

const SURVEY_BANNER = 'survey-banner';
const BANNER_COOKIE = 'survey-cookie';

export default () => {
  const banner = document.getElementById(SURVEY_BANNER);

  if (!isElement(banner)) return;

  const surveyCookie = CookieMgmt(BANNER_COOKIE);

  if (surveyCookie.getValue() === 'true') return;

  banner.setAttribute('aria-hidden', 'false');

  console.log('Loading banner JS');

  import(/* webpackChunkName: "survey_banner" */ './survey_banner').then(({ default: SurveyBanner }) => {
    SurveyBanner(SURVEY_BANNER);
  });
};
