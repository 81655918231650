export default () => {
  let hourChoice1 = document.getElementById("contact_lesson_replacement_replacement_lesson_time_hour");
  let minuteChoice1 = document.getElementById("contact_lesson_replacement_replacement_lesson_time_minute");
  let hourChoice2 = document.getElementById("contact_lesson_replacement_replacement_lesson_2_time_hour");
  let minuteChoice2 = document.getElementById("contact_lesson_replacement_replacement_lesson_2_time_minute");

  if(hourChoice1){
    hourChoice1.addEventListener('change', function(e) {
      if(hourChoice1.value == '22'){
        minuteChoice1.value = '00'
        minuteChoice1.disabled = 'disabled'
      } else {
        minuteChoice1.disabled = null
      }
    });
  } 

  if(hourChoice2){
    hourChoice2.addEventListener('change', function(e) {
      if(hourChoice2.value == '22'){
        minuteChoice2.value = '00'
        minuteChoice2.disabled = 'disabled'
      } else {
        minuteChoice2.disabled = null
      }
    });
  } 

};
