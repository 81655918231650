export const parseBoolean = value => {
  if (!/true|false/.test(value)) return null;

  return value === 'true';
};

export const parseNumber = value => {
  if (!/[0-9]*/.test(value)) return null;

  return parseInt(value, 2);
};
