import './stylesheets/calendar.scss';
import { format, parse } from 'date-fns';
import ContactForms, { setFormat } from './contact-form';

export const Parser = (dateString, f) => {
  const split = dateString.split('/').map(d => parseInt(d));
  
  const date = new Date(split[2], split[1] - 1, split[0]);
  return date;
};

const setPickers = pickers => {
  const membershipFreeze = ['start_date', 'finish_date'];

  const freezForm = document.querySelector('.freeze-form');

  if (freezForm) {
    return {
      freezeCals: pickers.filter(picker => membershipFreeze.includes(picker.id)),

      standardCals: pickers.filter(picker => !membershipFreeze.includes(picker.id)),
    };
  }

  return {
    freezeCals: [],
    standardCals: pickers,
  };
};

const getData = (picker)=>{
  const keys = ['minDate', 'maxDate', 'yearRange'];

  return keys.reduce((data, key)=>{
    console.log(key);
    if(!picker.dataset[key]) return data;
    const unparsed = picker.dataset[key];
    const parsed = (/(\[|\])+/.test(unparsed)) ? JSON.parse(unparsed) : Parser(unparsed);
    return {...data, [key]: parsed }
  }, {})
}

export default css => {
  const pickers = [...document.querySelectorAll(css)];

  if (pickers.length > 0) {
    const { freezeCals, standardCals } = setPickers(pickers);
    import(/* webpackChunkName: "pikaday" */ 'pikaday').then(({ default: Pikaday }) => {
      standardCals.forEach(picker => {
        const data = getData(picker);
        console.log('data', data);
        const cal = new Pikaday({
          ...data,
          field: picker,
          parse: Parser,
          yearRange:'1901:2020',
          toString: date => setFormat(date),
          onSelect: date => {
            picker.value = format(date, 'DD/MM/YYYY');
          },
        });
      });

      ContactForms(freezeCals, Pikaday);
    });
  }
};
