import { OpenNav } from '../actions/navitems';

import { getAttribute, getElement } from '../helpers';

import { SEARCH_BUTTON_ID, SEARCH_ID } from '../config';

// export const BUTTON_ID = 'search-open';
// export const SEARCH_ID = 'main-search';

export default ({ dispatch }, watcher) => {
  const button = getElement(SEARCH_BUTTON_ID);
  const search = getElement(SEARCH_ID);

  if (!button || !search) return;
  search.setAttribute('tab-index', -1);
  const inputs = [...search.querySelectorAll('input')];
  inputs.forEach(input => {
    input.disabled = true;
  });

  button.addEventListener('click', e => {
    e.preventDefault();
    const open = getAttribute(button, 'aria-pressed');

    button.setAttribute('aria-pressed', (!open).toString());
    search.setAttribute('aria-hidden', open.toString());
    search.setAttribute('tab-index', open ? 0 : -1);

    inputs.forEach(input => {
      input.disabled = open;
    });

    dispatch(OpenNav(false));
  });

  watcher(['open'], ({ open }) => {
    if (open) {
      button.setAttribute('aria-pressed', 'false');
      search.setAttribute('aria-hidden', 'true');
      search.setAttribute('tab-index', -1);

      inputs.forEach(input => {
        input.disabled = true;
      });
    }
  });
};
