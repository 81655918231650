// (() => {
//   const membership = document.getElementById('membership');
//   // const elms = [...document.querySelectorAll('. ul')];
//   if (membership) {
//     import(/* webpackChunkName: "content_carousel_carousel" */ './content_carousel_homepage').then(
//       ({ default: ContentCarousel }) => {
//         ContentCarousel();
//       }
//     );
//   }
// })();

if (document.body.className === 'home') {
  const centreLocatorToolbar = document.querySelector('.centre-locator-toolbar');

  if (centreLocatorToolbar) {
    import(/* webpackChunkName: "event_tracking_centre_locator" */ './event_tracking/centre_locator').then(
      ({ default: CentreLocatorToolbarTrackingModule }) => {
        const centreLocatorToolbarTracking = CentreLocatorToolbarTrackingModule();

        centreLocatorToolbarTracking.init(centreLocatorToolbar);
      }
    );
  }
}
