export default () => {
  const form = document.getElementById('new_venue_search');

  if(typeof(form) === 'undefined'){
    return;
  }

  if(form === null){
    return;
  }

  form.addEventListener('submit', () => {
    const submitButton = form.querySelector('[type=submit]');
    submitButton.value = 'Loading results...';
  });
};
