export default () => {

  let alert = document.querySelector('section.alert');
  let alertColor
  let alertControls = document.querySelector('.alerts-holder>.grey-panel');
  let alertRow = document.querySelector('.alerts-holder>div:nth-child(2)');
  let toggleBtn = document.querySelector('.alerts-holder__btn');

  if(alert){
    alertColor = window.getComputedStyle(alert).backgroundColor;
  }

  if(alertControls){
    
    if(alert){
      alertControls.style.backgroundColor = alertColor;  
    };

    toggleBtn.addEventListener('click', ()=> {
      alertControls.classList.toggle('alert-controls--expanded');
      alertRow.classList.toggle('alerts-row-expanded');
      if(alertControls.style.backgroundColor == alertColor){
        alertControls.style.backgroundColor = 'white';
        toggleBtn.innerHTML = "Hide Alerts"; 
      }else{
        alertControls.style.backgroundColor = alertColor;
        toggleBtn.innerHTML = "Service updates are available at this location"; 
      };      
    });

  };


};