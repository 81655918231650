import { toSnakeCase } from './convert_case';

export const setKey = k => {
  switch (k) {
    case 'firstName':
      return 'firstname';
    case 'lastName':
      return 'lastname';
    default:
      return toSnakeCase(k);
  }
};

export const PrepData = data =>
  Object.entries(data).reduce((d, [k, v]) => {
    if (k === 'type') return d;
    const key = setKey(k);
    return { ...d, [key]: v };
  }, {});

/* eslint-disable no-weak-types */
export const handleError = (next, type) => error =>
  next({
    error,
    type,
  });
/* eslint-enable no-weak-types */
export const handleSuccess = (next, type) => action =>
  next({
    ...action,
    type,
  });

export const isSuccess = ({ status }) => status >= 200 && status < 300;

const createResponse = method => data => ({
  method,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

export const postResponse = createResponse('POST');

export const postMultiResponse = data => ({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: data,
});

export const patchResponse = createResponse('PATCH');

export const fetchRequest = (url, sendData, processData = 'json') =>
  fetch(url, sendData)
    .then(async response => {
      const data = await response[processData]();
      return { data, response };
    })
    .then(({ data, response }) => ({ isSuccess: isSuccess(response), data }));
