import Manager from '../../manage_events';

export function getAttribute(el, attr, dataSet = false) {
  const data = dataSet ? el.dataset[attr] : el.getAttribute(attr);
  if (/^\d+$/.test(data)) return parseFloat(data, 10);
  if (/^true$/.test(data)) return true;
  if (/^false$/.test(data)) return false;
  return data;
}

export const ClickHandler = (dispatch, action) => e => {
  e.preventDefault();
  dispatch(action(e.target.id, true));
  const pressed = getAttribute(e.target, 'aria-pressed');

  e.target.setAttribute('aria-pressed', !pressed);
};

export const getElement = searchStr => {
  const el = /^\.|#/.test(searchStr) ? document.querySelector(searchStr) : document.getElementById(searchStr);
  if (!el) return null;

  return el;
};

export const getElements = searchStr => {
  const els = [...document.querySelectorAll(searchStr)];
  if (!els) return [];

  return els;
};

export const GetSub = ({ holderCSS, subCSS }) => id => {
  const btn = getElement(id);
  if (!btn) return;

  const holder = btn.closest(holderCSS);
  if (!holder) return;

  return holder.querySelector(subCSS);
};

export const SetRoots = (handler, ev = 'click') => ({ id }, parent) => {
  let button = getElement(id);
  if (!button) return;
  if (parent) {
    button = button.closest('li').querySelector('.sub-nav');
  }

  Manager.addEventListener(button, ev, handler);
};

export const getLinkNo = items =>
  items.reduce((n, { sub }) => {
    if (!sub) return n;
    return n + sub.length;
  }, items.length);

export const CheckIE = () => 'ActiveXObject' in window;
