import ContentSlider from './content_slider';
import ExpandingPanel from './expanding_panels';
import ResponsiveTable from './responsive_table';
import Sticky from './sticky';
import CmsNav from './cms-nav/index';

// Content Carousel
(() => {
  const contentCarousels = [...document.querySelectorAll('.content-carousel')];
  if (contentCarousels.length > 0) {
    import(/* webpackChunkName: "content_carousel" */ './content_carousel').then(({ default: ContentCarousel }) => {
      contentCarousels.forEach(
        contentCarousel =>
          new ContentCarousel({
            element: contentCarousel,
            innerClass: 'content-carousel-inner',
            itemClass: 'content-carousel-item',
          })
      );
    });
  }
})();

// Content Slider
(() => {
  ContentSlider();
})();

// Expanding Content
(() => {
  ExpandingPanel();
})();

(() => {
  ResponsiveTable();
})();

(() => {
  Sticky();
})();

(() => {
  CmsNav();
})();
