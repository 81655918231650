export default () => {

  const cmsNavPage = document.getElementById("primary-nav-cms");
  const cmsNavBtnArr = document.querySelectorAll(".desktop-nav__item");
  const cmsNavSubLvlArr = document.querySelectorAll(".sub-nav--level0");
  const searchBtn = document.getElementById("search-open");
  const searchBar = document.getElementById("main-search");
  const mobileMenuBtn = document.getElementById("menu-button");
  const mobileNavWrap = document.getElementById('nav-mobile-wrapper');
  const mobileNav = document.getElementById('nav-mobile');
  const mobileNavBtns0 = document.querySelectorAll('.mobile-nav__item-top__0');
  const mobileNavSubItems0 = document.querySelectorAll('.mobile-nav__sub-items__0');
  const mobileNavBtns1 = document.querySelectorAll('.mobile-nav__item-top__1');
  const mobileNavSubItems1 = document.querySelectorAll('.mobile-nav__sub-items__1');
  
  const cmsNavOpen = noindex => {
    if(cmsNavSubLvlArr[noindex]){    
      cmsNavSubLvlArr[noindex].ariaHidden = "false"; 
    }   
  }
  
  const cmsNavClose = ncindex => {    
    if(cmsNavSubLvlArr[ncindex]){
      cmsNavSubLvlArr[ncindex].ariaHidden = "true";    
    }
  }
 

  const mobileNavSubOpen = nsoindex => {
    if (mobileNavSubItems0[nsoindex].attributes['aria-hidden'].value == "true") {
      mobileNavSubItems0[nsoindex].setAttribute("aria-hidden", "false");
    } else {
      mobileNavSubItems0[nsoindex].setAttribute("aria-hidden", "true");
    }
  }    

  const mobileNavSubMenuOpen = nsmoindex => {
    if (mobileNavSubItems1[nsmoindex].attributes['aria-hidden'].value == "true") {
      mobileNavSubItems1[nsmoindex].setAttribute("aria-hidden", "false");
    } else {
      mobileNavSubItems1[nsmoindex].setAttribute("aria-hidden", "true");
    }
  }    
  
  if(cmsNavPage) {

    [...mobileNavBtns0].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        mobileNavSubOpen(index);
      });
    });

    [...mobileNavBtns1].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        mobileNavSubMenuOpen(index);
      });
    });

    [...cmsNavBtnArr].forEach((btn, index) => {
      btn.addEventListener('mouseover', () => {
        cmsNavOpen(index);
      });      
      btn.addEventListener('mouseout', () => {
          cmsNavClose(index);
      });
    });
    [...cmsNavSubLvlArr].forEach((sublvl, index) => {
      sublvl.addEventListener('mouseover', () => {
        cmsNavOpen(index);
      });
      sublvl.addEventListener('mouseout', () => {
        cmsNavClose(index);
      });
    });

    if(searchBtn){
      searchBtn.addEventListener('click', () =>{
        if (searchBar.attributes['aria-hidden'].value == "true") {
          searchBar.setAttribute("aria-hidden", "false");
        } else {
          searchBar.setAttribute("aria-hidden", "true");
        }
      });
    }

    
    if(mobileMenuBtn){
      mobileMenuBtn.addEventListener('click', () => {        
        if (mobileMenuBtn.attributes['aria-pressed'].value == "true") {
          mobileMenuBtn.setAttribute("aria-pressed", "false");
          setTimeout(function(){mobileNavWrap.setAttribute('style', 'height: 0vh;');}, 1000);        
          mobileNav.setAttribute('style', 'transform: translateY(-100%);');
        } else {
          mobileMenuBtn.setAttribute("aria-pressed", "true")
          mobileNavWrap.setAttribute('style', 'height: 500vh;');
          mobileNav.setAttribute('style', 'transform: translateY(0%);');
        }
      });
    }    

  };

};