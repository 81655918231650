const storeOption = option => {
  const opts = new Map();
  const type = option.value ? 'option' : 'placeholder';
  opts.set('type', type);
  opts.set('text', option.text);
  opts.set('value', option.value);
  opts.set('data', option.dataset);

  return opts;
};

const storeList = select =>
  [...select.children].map(el => {
    if (el.tagName === 'OPTION') {
      return storeOption(el);
    }

    const optGroup = new Map();
    optGroup.set('type', 'optgroup');
    optGroup.set('label', el.label);
    optGroup.set('children', storeList(el));
    return optGroup;
  });

const shouldShow = (value, businessSector = '') =>
  value === 'all' || businessSector.toLowerCase() === value.toLowerCase();

const filterOptions = (options, sector) => options.filter(opt => shouldShow(sector, opt.get('data').businessSector));

const createOptGroup = (opts, value) => {
  const opt = filterOptions(opts.get('children'), value);
  if (opt.length === 0) return [];

  const newOpts = new Map(opts);
  newOpts.set('children', opt);

  return [newOpts];
};

const FILTERED = [
  {
    conditional: opts => opts.get('type') === 'optgroup',
    filter: createOptGroup,
  },
  {
    conditional: (opts, value) => {
      if (opts.has('data')) {
        return shouldShow(value, opts.get('data').businessSector);
      }
      return false;
    },
    filter: opts => [opts],
  },
  {
    conditional: opts => opts.get('type') === 'placeholder',
    filter: opts => [opts],
  },
];

export default select => {
  const options = storeList(select);
  return value =>
    options.reduce((filtered, opts) => {
      const newList = FILTERED.reduce((list, { conditional, filter }) => {
        if (conditional(opts, value)) {
          return filter(opts, value);
        }

        return list;
      }, []);

      return filtered.concat(newList);
    }, []);
};
