import CookieMgmt from '@djforth/cookie_mgmt_fp';
import { truncate } from 'lodash';

const toggle = document.getElementById('analytics-cookies');
const close = document.getElementById('cookie-prompt-close');
const cookiePrompt = document.getElementById('cookie-prompt');
const rejectLink = document.querySelector('.cookie-reject-link');
const settingsLink = document.querySelector('.cookie-settings-link');
const settings = document.querySelector('.cookie-settings');
const COOKIE_PERMISSION = 'permission';
const NO_COOKIE = 'no cookies';

export default () => {
  if (!toggle) return;

  const permissionCookie = CookieMgmt(COOKIE_PERMISSION);
  const noCookie = CookieMgmt(NO_COOKIE);

  if (settingsLink) {
    settingsLink.addEventListener('click', () => {
      settings.classList.toggle('toggle-settings');
    });
  }

  close.addEventListener('click', () => {
    permissionCookie.createCookie(toggle.checked, 365);
    updateCookieConsent(toggle.checked);
    cookiePrompt.setAttribute('aria-hidden', 'true');
    cookiePrompt.style.display = 'none';
  });

  rejectLink.addEventListener('click', () => {
    permissionCookie.createCookie(false, 365);
    updateCookieConsent(false);
    noCookie.createCookie(true, 365);
    cookiePrompt.setAttribute('aria-hidden', 'true');
    cookiePrompt.style.display = 'none';
  });

  if (permissionCookie.getValue() === 'true') {
    toggle.checked = true;
    cookiePrompt.setAttribute('aria-hidden', 'true');
    cookiePrompt.style.display = 'none';
  } else if (permissionCookie.getValue() === 'false') {
    cookiePrompt.setAttribute('aria-hidden', 'true');
    cookiePrompt.style.display = 'none';
  }

  function updateCookieConsent(truefalse) {
    var consentValue = truefalse ? 'yes' : 'no'
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      "event": "cookie_consent_updated",
      "cookie_consent": consentValue,
    });
  }
};
