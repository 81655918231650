export default () => {

  const form = document.querySelector('.pupil-refund-request')

  if(form){
    const containers = document.querySelectorAll('.pupil-refund-title')

    containers.forEach(cont => {
      cont.addEventListener("click", () => {
        cont.closest('.pupil-toggle').classList.toggle('open');
      })
    });

  }

};
