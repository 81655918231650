import { isEqual } from 'lodash';

const compareState = (store, ...keys) => {
  let state = store.getState();
  return newState => {
    const stateChange = keys.reduce((changed, key) => {
      /* istanbul ignore if  */
      if (changed) return changed;
      return !isEqual(state[key], newState[key]);
    }, false);
    state = newState;
    return stateChange;
  };
};

const CheckDevice = devices => ({ device }) => {
  if (devices.length === 0) return true;
  return devices.includes(device);
};

export default store => (keys, stateChange, actions, devices = []) => {
  const compare = compareState(store, ...keys);
  const checkDevice = CheckDevice(devices);
  store.subscribe(() => {
    const state = store.getState();
    // Check if watchers should firer
    if (!checkDevice(state)) return;
    if (!compare(state)) {
      return;
    }
    // Fires functions if available
    if (stateChange) stateChange(state, store);
    if (actions) actions(store.dispatch, state);
  });
};
