// import checkUpdate from './check_update';
import manageDB from './manage_db';
import getData from './get_data';
import updateData from './update_data';

const localData = async (schema, query) => {
  const data = await Promise.all(
    schema.map(async ({ store: objStore }) => {
      const d = await query.getAll(objStore);
      return [objStore, d];
    })
  );

  return data.reduce((cur, [objStore, d]) => ({ ...cur, [objStore]: d }), {});
};

const DataFetch = updater => async (fetchData, processor, attrs) => {
  const data = await fetchData();
  await updater(data, processor, attrs);
  return data;
};

function getInternetExplorerVersion() {
  let rv = -1;
  if (navigator.appName == 'Microsoft Internet Explorer') {
    const ua = navigator.userAgent;
    const re = new RegExp('MSIE ([0-9]{1,}[\\.0-9]{0,})');
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  } else if (navigator.appName === 'Netscape') {
    const ua = navigator.userAgent;
    const re = new RegExp('Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})');
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  }
  return rv;
}

const hasIndexDB = () => 'indexedDB' in window || !(getInternetExplorerVersion() > -1);

export default async (store, schema, version) => {
  let data;
  const updateKey = `${store}Update`;
  const manager = hasIndexDB() ? await manageDB(store, schema, version) : null;
  const updater = updateData(manager, updateKey);
  const fetcher = DataFetch(updater);
  let mainUrl;
  let processor;
  let checker;

  return {
    get: (objStore, key) => {
      if (manager === null) {
        return Promise.resolve(data[objStore][key]);
      }

      return manager.get(objStore, key);
    },
    getAll: () => data,
    getAllStore: objStore => {
      if (manager === null) {
        return Promise.resolve(data[objStore]);
      }
      return manager.getAll(objStore);
    },
    set: (objStore, key, val) => manager.set(objStore, key, val),
    setCheckUpdate: ch => {
      checker = ch;
    },
    setProcessor: p => {
      processor = p;
    },
    setUrl: url => {
      mainUrl = url;
    },

    update: async attrs => {
      const url = typeof mainUrl === 'string' ? mainUrl : mainUrl(attrs);
      const fetchData = getData(url);

      if (manager === null) {
        data = await fetchData();
        return data;
      }

      const shouldUpdate = await checker(manager, attrs);
      if (shouldUpdate) {
        data = await fetcher(fetchData, processor, attrs);
        return data;
      }

      data = await localData(schema, manager);
      return data;
    },
    updateService: async attrs => {
      // alert(url);
      const url = typeof mainUrl === 'string' ? mainUrl : mainUrl(attrs);
      const fetchData = getData(url);
      if (manager === null) {
        data = await fetchData();
        return data;
      }

      const shouldUpdate = await checker(manager, attrs);
      if (shouldUpdate) {
        if ('serviceWorker' in navigator) {
          try {
            navigator.serviceWorker.addEventListener('message', event => {
              console.log(event.data.msg, event.data.url);
            });

            navigator.serviceWorker.controller.postMessage({ command: `sync-${store}`, ...attrs });
          } catch (er) {
            // console.error('sync failed');
            data = await fetcher(fetchData, processor, attrs);
            return data;
          }
        } else {
          data = await fetcher(fetchData, processor, attrs);
          return data;
        }
      }

      data = await localData(schema, manager);
      return data;
    },
  };
};
