export default () => {

  let height = document.querySelector('#tiara9_referral_answers_attributes_9_value');
  let weight = document.querySelector('#tiara9_referral_answers_attributes_10_value');
  let figs = [height, weight];
  let bmi

  const bmiDiv = document.querySelector('#tiara9-bmi');
  const bmiVal = document.querySelector('#tiara9-bmi-value');

  if(!bmiDiv){return};

  [...figs].forEach(fig => {
    fig.addEventListener('change', () => {
      bmi = weight.value / ((height.value/100) * (height.value/100));
      if(weight.value && height.value){
        console.log("BMI:", bmi.toFixed(2));
        bmiVal.innerHTML = bmi.toFixed(2);  
        bmiDiv.classList.add('show');
      }
    })
  });

};