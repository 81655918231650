export default () => {
  const start_date = document.getElementById('refund_form_start_date');
  const finish_date = document.getElementById('refund_form_finish_date');

  if (start_date === null) { return; }

  start_date.addEventListener('change', (event) => {
    checkDates(start_date, finish_date);
  });

  finish_date.addEventListener('change', (event) => {
    checkDates(start_date, finish_date);
  });

  function dateToAmerican(date){
    var mmddyyyy = date.split('/');
    return mmddyyyy[1] + '/' + mmddyyyy[0] + '/' + mmddyyyy[2]
  }

  function checkDates(start_date, finish_date) {
    if(start_date.value === '') { 
      finish_date.value = '';
      return; 
    }
    if(finish_date.value === '') {
      finish_date.value = start_date.value;
    } else {
      var start_date_date = Date.parse(dateToAmerican(start_date.value)); 
      var finish_date_date = Date.parse(dateToAmerican(finish_date.value)); 
       if(finish_date_date < start_date_date) {
        finish_date.value = start_date.value;
      } 
    }
  }
}
