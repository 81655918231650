export default () => {
  const facilityTypeDropdown = document.getElementById(
    'facility_type_selector'
  );
  const venueDropdown = document.getElementById('venue_selector');
  const libraries = [...document.querySelectorAll('.libraries')];
  const leisureCentres = [...document.querySelectorAll('.leisure')];
  const childrenCentres = [...document.querySelectorAll('.children')];

  if (!facilityTypeDropdown || !venueDropdown) return;

  facilityTypeDropdown.addEventListener('change', () => {
    if (facilityTypeDropdown.value === 'libraries') {
      libraries.forEach((centre) => {
        centre.style.display = 'block';
      });
      leisureCentres.forEach((centre) => {
        centre.style.display = 'none';
      });
      childrenCentres.forEach((centre) => {
        centre.style.display = 'none';
      });
    } else if (facilityTypeDropdown.value === 'leisure') {
      libraries.forEach((centre) => {
        centre.style.display = 'none';
      });
      childrenCentres.forEach((centre) => {
        centre.style.display = 'none';
      });
      leisureCentres.forEach((centre) => {
        centre.style.display = 'block';
      });
    } else if (facilityTypeDropdown.value === 'children') {
      libraries.forEach((centre) => {
        centre.style.display = 'none';
      });
      childrenCentres.forEach((centre) => {
        centre.style.display = 'block';
      });
      leisureCentres.forEach((centre) => {
        centre.style.display = 'none';
      });
    } else {
      libraries.forEach((centre) => {
        centre.style.display = 'block';
      });
      childrenCentres.forEach((centre) => {
        centre.style.display = 'block';
      });
      leisureCentres.forEach((centre) => {
        centre.style.display = 'block';
      });
    }
  });
};
