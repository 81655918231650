export const MENU_ID = 'menu-button';
export const MOBILE_HOLDER_ID = 'nav-mobile';
export const MOBILE_WRAPPER_ID = 'nav-mobile-wrapper';

export const MOBILE_SUB_HOLDER = '.mobile-nav__item';
export const MOBILE_SUB_CLASS = '.mobile-nav__items--level0';
export const MOBILE_BACK_CLASS = '.mobile-nav__link--back';

export const SEARCH_BUTTON_ID = 'search-open';
export const SEARCH_ID = 'main-search';

export const DESKTOP_SUB_CLASS = '.sub-nav--level0';

export const DESKTOP_SUB_HOLDER = '.desktop-nav__item--level0';

export const MAIN_NAV = 'primary-nav';
