import FontFaceObserver from 'fontfaceobserver';

const GarageGothic = new FontFaceObserver('Garage Gothic');
GarageGothic.load()
  .then(() => {
    document.body.classList.add('GarageGothic-loaded');
  })
  .catch(err => {
    document.body.classList.add('GarageGothic-failed');
  });

const Lato = new FontFaceObserver('Lato');
Lato.load()
  .then(() => {
    document.body.classList.add('Lato-loaded');
  })
  .catch(err => {
    document.body.classList.add('Lato-failed');
  });
