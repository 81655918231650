// Borough filter for venue search
(() => {
  const venueSearch = document.getElementById('new_venue_search');

  if (venueSearch) {
    import(/* webpackChunkName: "borough_filter" */ './borough_filter').then(
      ({ default: BoroughFilter }) => {
        BoroughFilter(
          'new_venue_search',
          'venue_search_activity_type_id',
          'venue_search_facility_type_id'
        );
      }
    );
  }
})();

(() => {
  const map = document.getElementById('google-map');

  if (map) {
    import(/* webpackChunkName: "map" */ '@djforth/gmaps_helper').then(
      ({ default: Maps }) => {
        Maps('AIzaSyCNO6nOBSGTYverQD5nFnwUG_h-O206NfI').load();
      }
    );
  }
})();
