export default () => {

  const venueDropdown = document.getElementById('contact_venue_hire_enquiry_venue_id');
  const roomField = document.querySelector('.contact_venue_hire_enquiry_hireable_facility_id');
  let roomOptions = document.querySelectorAll('#contact_venue_hire_enquiry_hireable_facility_id option')
  let venueID
  
  const venueListener = () => {
    venueID = venueDropdown.value;
    roomField.style.display = 'block';
    roomOptions.forEach(option =>{
      if(option.classList == 'venue-' + venueID){
        option.style.display = 'block';
      }else{
        option.style.display = 'none';
      }
    })  
  }

  if(venueDropdown){
    venueDropdown.onchange = venueListener;
  }

};