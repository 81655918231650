import { isSuccess as successCheck } from '../../../../components/fetch_helpers/post_helpers';

import processList from './process_items';

const getData = async ({ getAllStore }) => {
  let response;
  try {
    response = await getAllStore('navitems');
  } catch (error) {
    return { error, isSuccess: false };
  }

  return { response, isSuccess: true };
};

export default async db => {
  const fetcher = await getData(db);

  if (fetcher.isSuccess && fetcher.response) {
    const data = processList(fetcher.response);
    // Sends to reducer
    return { ...fetcher, data };
  }

  return fetcher;
};
