export default () => {
  const accountNumber = document.getElementById('refund_form_payee_account_number');
  const sortCode = document.getElementById('refund_form_payee_sort_code');

  if (accountNumber === null) { return; }

  accountNumber.addEventListener('keyup', () => {
    sanitizeInput(accountNumber,  8);
  })

  sortCode.addEventListener('keyup', () => {
    sanitizeInput(sortCode,  6);
  })

  function sanitizeInput(input, maxChars) {
    var txt = input.value;
    input.value = txt.replace(/[^0-9]+/, '').substring(0, maxChars);
  }
}
