import Pikaday from 'pikaday';

export default function() {

  const dobField = document.querySelector('#tiara9_referral_answers_attributes_3_value');

  if(!dobField) return;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth()).padStart(2, '0');
  let yyyy = today.getFullYear();
  
  let minYear = yyyy - 120;
  let maxYear = yyyy;

  const picker = new Pikaday({
    field: dobField,
    format: 'YYYY MM DD',
    minDate: new Date(minYear, mm, dd),
    maxDate: new Date(maxYear, mm, dd),
    defaultDate : new Date(maxYear, mm, dd),
    yearRange: 120,
    toString(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month}/${day}`; 
    },
  });
  
}
