export default () => {

  let form = document.querySelector('.new_contact_pre_sale')
  let expanderBtns = [...document.querySelectorAll('.list-expander-btn')]
  let modal = document.querySelectorAll('.modal-container')

  expanderBtns.forEach((btn, i) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault()
      modal[i].classList.toggle('open')
    })
  });

  modal.forEach((modal, i) => {
    modal.addEventListener('click', () => {
      console.log(modal)
      modal.classList.toggle('open')      
    })
  });



};