import CookieMgmt from '@djforth/cookie_mgmt_fp';
import { checkElements, isElement } from '../helpers/check_elements';
import curry from '../helpers/curry';
import { parseBoolean } from '../helpers/parse_data';

import './stylesheets/index.scss';

const FEEDBACK_ID = 'feedback';
const FEEDBACK_BTN = 'feedback-btn';
const FEEDBACK_CONTENT = 'feedback-content';

const CLOSE_CLASS = 'feedback--closed';
const OPEN_CLASS = 'feedback--open';

const AUTO_OPEN = 3000;

const HIDE_SURVEY = 'hide-survey';
const COOKIE_SURVEY = 'survey-popout';
const COOKIE_OPENED = 'survey-opened';

const getElements = () => ({
  feedback: document.getElementById(FEEDBACK_ID),
  btn: document.getElementById(FEEDBACK_BTN),
  content: document.getElementById(FEEDBACK_CONTENT),
});

const openedCounter = () => {
  const openedCookie = CookieMgmt(COOKIE_OPENED);
  let opened = parseInt(openedCookie.getValue()) || 0;

  return (increase = false) => {
    if (increase) opened += 1;
    openedCookie.createCookie(opened, 3 * 30);

    return opened < 3;
  };
};

const isOpen = el => parseBoolean(el.getAttribute('aria-pressed'));

const SetPosition = (feedback, btn, content, opened) => {
  btn.setAttribute('aria-pressed', !opened);
  content.setAttribute('aria-hidden', opened);

  feedback.classList.toggle(CLOSE_CLASS);
  feedback.classList.toggle(OPEN_CLASS);
};

const forceOpen = setPosition =>
  setTimeout(() => {
    setPosition(false);
  }, AUTO_OPEN);

const hideSurvey = surveyCookie => {
  const hide = document.getElementById(HIDE_SURVEY);
  if (!isElement(hide)) return;

  hide.addEventListener('click', e => {
    surveyCookie.createCookie(e.target.checked, 3 * 30);
  });
};

export default () => {
  const { feedback, btn, content } = getElements();

  if (!checkElements(btn, feedback, content)) return;

  const surveyCookie = CookieMgmt(COOKIE_SURVEY);

  const cookie = surveyCookie.getValue();
  if (cookie === 'true') {
    return;
  }

  const opened = openedCounter();

  feedback.setAttribute('aria-hidden', 'false');

  const setPosition = curry(SetPosition, feedback, btn, content);
  let opener;
  if (opened() && window.innerWidth > 500) {
    opener = forceOpen(setPosition);
  }

  hideSurvey(surveyCookie);

  btn.addEventListener('click', e => {
    e.preventDefault();
    const open = isOpen(e.currentTarget);
    setPosition(open);
    clearTimeout(opener);

    if (open) {
      opened(true);
    }
  });
};
