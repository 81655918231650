/* @flow */
export const CHANGE_DEVICE = 'CHANGE_DEVICE';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_FAIL = 'FETCH_DATA_FAIL';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const MOVE = 'MOVE';
export const LEVEL_MOVE = 'LEVEL_MOVE';
export const OPEN_NAV = 'OPEN_NAV';
export const RESET_ACTIVE = 'RESET_ACTIVE';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_FOCUS = 'SET_FOCUS';

export const ChangeDevice = device => ({
  device,
  type: CHANGE_DEVICE,
});

export const FetchData = () => ({
  type: FETCH_DATA,
});

export const Move = direction => ({
  direction,
  type: MOVE,
});

export const LevelMove = direction => ({
  direction,
  type: LEVEL_MOVE,
});

export const OpenNav = open => ({
  open,
  type: OPEN_NAV,
});

export const ResetActive = () => ({
  type: RESET_ACTIVE,
});

export const SetActive = (active, level = null) => ({
  active,
  level,
  type: SET_ACTIVE,
});

export const SetFocus = focus => ({
  focus,
  type: SET_FOCUS,
});
