import { OpenNav } from '../actions/navitems';

import { MENU_ID } from '../config';

import Manager from '../../manage_events';

import { getAttribute, getElement } from '../helpers';

const addClick = dispatch => {
  const menu = getElement(MENU_ID);

  if (!menu) return;
  Manager.addEventListener(menu, 'click', e => {
    e.preventDefault();

    const pressed = getAttribute(menu, 'aria-pressed');

    menu.setAttribute('aria-pressed', !pressed);
    dispatch(OpenNav(!pressed));
  });
};

export default ({ dispatch }, watcher) => {
  addClick(dispatch);

  watcher(['open'], ({ open }) => {
    if (!open) {
      const menu = getElement(MENU_ID);
      if (!menu) return;
      menu.setAttribute('aria-pressed', 'false');
    }
  });

  watcher(['device', 'navitems'], ({ device }) => {
    if (device !== 'mobile') return;

    Manager.flush();
    addClick(dispatch);
  });
};
