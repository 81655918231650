// import 'pwacompat';
// import addToHome from './add_to_home';

(function() {
  if ('serviceWorker' in navigator) {
    const swPath = '/sw.js';
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(swPath)
        .then(registration => {
          console.log('SW registered: ', registration);
        })
        .catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
    });

    // addToHome();
  }
})();
