import FacilitiesFilter from './contact_forms_new/facilities_filter';
import ShowAdditional from './contact_forms_new/show_additional';

const contactForm = [...document.querySelectorAll('.contact-form')];

(() => {
  FacilitiesFilter();
  ShowAdditional();
})();

const contactLibrary = document.getElementById('contact_form_query_type');
if (contactLibrary) {
  import(/* webpackChunkName: "contact_form" */ './contact_form_library').then(
    ({ default: ContactFormLibrary }) => {
      ContactFormLibrary();
    }
  );
}

// Contact Form corporate
const corpForm = document.getElementById('contact-form-corporate');
if (corpForm) {
  import(
    /* webpackChunkName: "contact_form" */ './contact_form_corporate'
  ).then(({ default: ContactFormC }) => {
    const contactFormCorporate = ContactFormC('contact-form-corporate');
    contactFormCorporate.config({
      filterClasses: ['employee', 'employer'],
      selectClasses: ['type']
    });
    contactFormCorporate.init();
  });
}
// End Contact Form corporate
