export default () => {
  const reason = document.getElementById("lesson_course_enquiry_reason_for_enquiry");
  const reasonOther = document.getElementById("lesson_course_enquiry_other_reason_for_enquiry_wrapper");

  if(typeof(reason) === 'undefined'){
    return;
  }

  if(reason === null){
    return;
  }

  reason.addEventListener(
    'change',
    e => {
      var v = reason.options[reason.selectedIndex].value;
      var label = reasonOther.querySelector('label');
      var input = reasonOther.querySelector('textarea');
      if(v == 'Other') {
        label.className = 'text required';
        input.className = 'text required';
        input.required = true;
        reasonOther.style.display = 'block';
      } else{
        label.className = 'text optional';
        input.className = 'text optional';
        input.required = false;
        reasonOther.style.display = 'none';
      }
    },
    false
  );

  const courseType = document.getElementById("lesson_course_enquiry_course_type");
  const courseTypeOther = document.getElementById("lesson_course_enquiry_course_type_other_wrapper");

  if(typeof(courseType) === 'undefined'){
    return;
  }

  if(courseType === null){
    return;
  }

  courseType.addEventListener(
    'change',
    e => {
      var v = courseType.options[courseType.selectedIndex].value;
      var label = courseTypeOther.querySelector('label');
      var input = courseTypeOther.querySelector('input');
      if(v == 'Other') {
        label.className = 'string required';
        input.className = 'string required';
        input.required = true;
        courseTypeOther.style.display = 'block';
      } else{
        label.className = 'string optional';
        input.className = 'string optional';
        input.required = false;
        courseTypeOther.style.display = 'none';
      }
    },
    false
  );

  const reasonText = document.querySelector('#reason-for-enquiry-extra-text');
  const reasonDrop = document.querySelector('#lesson_course_enquiry_reason_for_enquiry');

  if(reasonText){

    reasonDrop.addEventListener('change', () => {
      if(reasonDrop.value == 'Change Day' || reasonDrop.value == 'Change Time' || reasonDrop.value == 'Change Centre'){
        reasonText.style = 'display:block'
      } else {
        reasonText.style = 'display:none'
      }
    })

  }
};
