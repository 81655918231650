// Creates Id based on title
const createID = (title, id = '') => {
  const i = id !== '' ? `${id}-` : '';
  const newId = title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s]|\s/gi, g1 => {
      if (g1 === ' ') return '-';
      return '';
    });
  return i + newId;
};

// Processes JSON creating id's
const processesList = list =>
  list
    .filter(({ title }) => title !== '')
    .map(item => {
      // const id = item.id
      const title = item.title.trim(); // Removes whitespace
      // If sub items will recursively loop of subs to add id's
      if (Array.isArray(item.sub) && item.sub.length > 0) {
        return { ...item, sub: processesList(item.sub), title };
      }
      return { ...item, title };
    });

export default processesList;
