import RootButton from './roots_button';
import OpenSubs from './open_subs';
import SetSubs from './set_subs';
import Keyboard from './keyboard';

const handleFirstTab = e => {
  if (e.keyCode === 9) {
    document.body.classList.add('keyboard-user');
    window.removeEventListener('keydown', handleFirstTab, false);
  }
};

window.addEventListener('keydown', handleFirstTab, false);

export default (store, watcher) => {
  Keyboard(store, watcher);
  RootButton(store, watcher);
  OpenSubs(watcher);
  SetSubs(store, watcher);
};
