import Manager from '../../manage_events';

import { CheckIE, getLinkNo, getElement, GetSub } from '../helpers';

import { DESKTOP_SUB_CLASS, DESKTOP_SUB_HOLDER } from '../config';

import { SetActive } from '../actions/navitems';

const getSub = GetSub({ holderCSS: DESKTOP_SUB_HOLDER, subCSS: DESKTOP_SUB_CLASS });

const SetWide = ({ id, sub }) => {
  if (!sub) return null;
  const linkNo = getLinkNo(sub);
  if (linkNo > 8) {
    const section = getSub(id);
    section.style.width = '450px';
  }
};

const mouseEnter = (sub, dispatch) => {
  Manager.addEventListener(sub, 'mouseenter', e => {
    const btn = getElement(sub.dataset.id);
    if (btn) {
      clearTimeout(btn.timeoutID);
    }
  });
};

const mouseLeave = (sub, dispatch) => {
  Manager.addEventListener(sub, 'mouseleave', e => {
    const btn = getElement(sub.dataset.id);
    if (btn) {
      e.target.timeoutID = setTimeout(() => {
        dispatch(SetActive(null));

        e.target.setAttribute('aria-pressed', 'false');
      }, 50);
    }
  });
};

const setSubSection = ({ id }, dispatch) => {
  const sub = getSub(id);
  if (!sub) return null;
  sub.dataset.id = id;
  sub.setAttribute('tab-index', '-1');
  mouseEnter(sub);
  mouseLeave(sub, dispatch);
};

const focusEvent = (level, dispatch) => ({ id, sub }) => {
  const el = getElement(id);

  if (el) {
    Manager.addEventListener(el, 'focus', e => {
      dispatch(SetActive(e.target.id, level));
    });
  }

  if (sub) {
    sub.forEach(focusEvent(level + 1, dispatch));
  }
};

const trackFocus = (item, dispatch) => {
  if (item.sub) {
    item.sub.forEach(focusEvent(1, dispatch));
  }
};

export default ({ getState, dispatch }, watcher) => {
  const { device: d, navitems: ni } = getState();

  if (d !== 'mobile' && ni) {
    ni.forEach(item => {
      if (CheckIE()) SetWide(item);
      setSubSection(item, dispatch);
      trackFocus(item, dispatch);
    });
  }

  watcher(['device', 'navitems'], ({ device, navitems }) => {
    if (device !== 'mobile') {
      navitems.forEach(item => {
        if (CheckIE()) SetWide(item);
        setSubSection(item, dispatch);
      });
    }
  });
};
