export default () => {
  const checkbox = [...document.querySelectorAll('.check_boxes')];
  const max = 3;

  if (checkbox) {
    for (var i = 0; i < checkbox.length; i++)
      checkbox[i].onclick = selectiveCheck;

    function selectiveCheck() {
      const checkedChecks = [
        ...document.querySelectorAll('.check_boxes:checked'),
      ];
      if (checkedChecks.length >= max + 1) return false;
    }
  }
};
