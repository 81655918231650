import { MOBILE_BACK_CLASS, MOBILE_SUB_CLASS, MOBILE_SUB_HOLDER } from '../config';

import Manager from '../../manage_events';

import { SetActive } from '../actions/navitems';

import { getAttribute, GetSub, getElements } from '../helpers';

const getSub = GetSub({ holderCSS: MOBILE_SUB_HOLDER, subCSS: MOBILE_SUB_CLASS });

const SetBack = dispatch => btn => {
  Manager.addEventListener(btn, 'click', e => {
    e.preventDefault();
    dispatch(SetActive());
  });
};

const SetSubSections = setBack => {
  const sections = getElements(`${MOBILE_SUB_HOLDER} ul,  ${MOBILE_BACK_CLASS}`);

  if (sections.length === 0) return null;

  sections.forEach(el => {
    el.setAttribute('aria-hidden', 'true');
    if (el.nodeName === 'BUTTON') {
      setBack(el);
    }
  });
};

const SetSub = setBack => ({ id }) => {
  const subSection = getSub(id);
  if (!subSection) return null;
  subSection.setAttribute('aria-hidden', 'true');
  SetSubSections(setBack);
};

export default ({ dispatch, getState }, watcher) => {
  const { device: dev, navitems: ni } = getState();
  const setBack = SetBack(dispatch);
  if (ni && dev === 'mobile') {
    ni.forEach(SetSub(setBack));
  }

  watcher(['device', 'navitems'], ({ device, navitems }) => {
    if (device === 'mobile') {
      navitems.forEach(SetSub(setBack));
    }
  });
};
