import { parseBoolean } from '../../helpers/parse_data';

const showHide = (id, value) => {
  const el = document.getElementById(id);
  el.setAttribute('aria-hidden', !value);
};

const setAdditional = el => {
  el.addEventListener(
    'change',
    e => {
      const id = el.dataset.showAdditional;
      showHide(id, parseBoolean(e.target.value));
    },
    false
  );
};

export default () => {
  const additional = [...document.querySelectorAll('[data-show-additional]')];
  if (additional.length === 0) return;

  additional.forEach(setAdditional);
};
