export default () => {
  const categories = [...document.querySelectorAll('.faqcat')];

  categories.forEach((category) => {
    const btn = category.querySelector('.faq-multiple-category__btn');
    const list = category.querySelector('.faq-list');

    btn.addEventListener('click', () => {
      list.classList.toggle('open-faq');
    });
  });
};
