import { manageTranslations as TranslationHelper } from '@morsedigital/i18n_helper';
import { getSection } from './helpers';

const pn = TranslationHelper('javascript')('primary_navigation');

const mobileNavCss = level => `
  mobile-nav__items mobile-nav__items--level${level}
`;

const mobileNavLinkCss = (level = 0) => `mobile-${getSection(level)}__link`;

const backBtn = (title, id) => `
  <li class="mobile-nav__item">
    <button class="mobile-nav__link mobile-nav__link--back" type="button" id="back-btn-${id}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.18 8.51" class="mobile-nav__icon mobile-nav__icon--back">
        <title>${pn('back_to', { title })}</title>
        <path
          d="M1,1,5,4.5,1,8"
          transform="translate(-0.34 -0.25)"
          style="fill: none; stroke-miterlimit: 10; stroke-width: 1;"
        ></path>
      </svg>
      ${pn('back_to', { title })}
    </button>
  </li>
`;

const callToAction = ({ path, title }) => `
  <li class="cta-item">
    <a href="${path}" rel="no-follow" class="cta__btn cta__btn--mobile"> ${title}</a>
  </li>
`;

const CallToActions = ctas => `
  <ul class="cta cta--mobile">
    ${ctas.map(callToAction).join('')}
  </ul>
`;

const hamburger = () => `
  <button id="menu-button" class="menu-btn" aria-pressed="false" aria-label="menu-button" type="button">
    <svg class="menu-btn__icon" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <title>Open navigation</title>
      <path class="h t" d="M10.5 10l17 0"></path>
      <path d="M10.5 19l17 0"></path>
      <path class="h b" d="M10.5 28l17 0"></path>
      <path class="x" d="M19 10.5l0 17"></path>
    </svg>
  </button>
`;

const baseElements = (nav, language) => `
  ${hamburger()}
  <div class="mobile-nav" id="nav-mobile-wrapper">
    <div class="mobile-nav-wrapper" id="nav-mobile">
      <ul class="${mobileNavCss(0)}">
        ${nav}
      </ul>
    </div>
    ${language || ''}
  </div>
`;

const subButton = (id, title) => `
  <button class="mobile-nav__link" type="button" id="${id}">
    ${title}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.18 8.51" class="mobile-nav__icon">
      <title>${title}</title>
      <path
        d="M1,1,5,4.5,1,8"
        transform="translate(-0.34 -0.25)"
        style="fill: none; stroke-miterlimit: 10; stroke-width: 1;"
      ></path>
    </svg>
  </button>
`;

const subLink = ({ id, path, title }, level) => `
  <li class="mobile-nav__item">
    <a href="${path}" id="${id}" class="${mobileNavLinkCss(level)}">${title}</a>
  </li>
`;

const subSection = ({ id, sub, title }, level) => `
  <li class="mobile-nav__item">
    ${subButton(id, title)}
    <ul class="${mobileNavCss(level)}" aria-hidden="true">
      ${backBtn(title, id)}
      ${sub
        .map(item => {
          if (item.sub) {
            return subSection(item, level + 1);
          }
          return subLink(item, level + 1);
        })
        .join('')}
    </ul>
  </li>
`;

export default ({ navitems, join }) => {
  const nav = document.getElementById('primary-nav');
  if (!nav) return;

  const cta = [
    { title: pn('cta_buttons.join'), path: join },
    { title: pn('cta_buttons.find_centre'), path: '/centre-locator' },
    { title: pn('cta_buttons.lesson_or_course'), path: 'https://www.better.org.uk/what-we-offer/lessons-and-courses' },
    { title: pn('cta_buttons.activity'), path: nav.dataset.book },
  ];
  const template = navitems
    .map(item => {
      if (item.sub) {
        return subSection(item, 0);
      }
      return subLink(item, 0);
    })
    .join('');

  const languageChooser = nav.dataset.languageChooser ? JSON.parse(nav.dataset.languageChooser) : '';
  nav.innerHTML = baseElements(`${CallToActions(cta)} ${template}`, languageChooser);
};
