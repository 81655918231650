import { first } from "lodash";

export default () => {

  const accessDropdowns = [...document.querySelectorAll('.contact_refugees_application_applicants_access_type select')]
  const accessOptionsFields = [...document.querySelectorAll('.refugee-access-option-fields')]
  const firstNameFields = [...document.querySelectorAll('.contact_refugees_application_applicants_firstname input')]
  const lastNameFields = [...document.querySelectorAll('.contact_refugees_application_applicants_lastname input')]
  const dobFields = [...document.querySelectorAll('.contact_refugees_application_applicants_dob input')]
  const emailFields = [...document.querySelectorAll('.contact_refugees_application_applicants_email input')]
  const phoneFields = [...document.querySelectorAll('.contact_refugees_application_applicants_phone input')]
  const addAnother = [...document.querySelectorAll('.refugee-add-application')]
  const singleFormContainer = [...document.querySelectorAll('.refugee-single-form-container')]
  
  if(accessDropdowns) {

    if(addAnother[3]){
      addAnother[3].style.display = 'none';
    }  
    
    accessDropdowns.forEach((dropdown, i) => {
      dropdown.addEventListener('change', (e) => {
        if(e.target.value === 'lesson_or_course') {
          accessOptionsFields[i].classList.add('refugee-fields--expand')
        } else {
          accessOptionsFields[i].classList.remove('refugee-fields--expand')
        }
      });
    });  
    
    addAnother.forEach((addbtn, i) => {
      let firstName = document.getElementById('contact_refugees_application_applicants_attributes_'+ (i+1) +'_firstname');
      let lastName = document.getElementById('contact_refugees_application_applicants_attributes_'+ (i+1) +'_lastname');
      let dob = document.querySelector('[name="contact_refugees_application[applicants_attributes]['+ (i+1) +'][dob]"]');
      let email = document.getElementById('contact_refugees_application_applicants_attributes_'+ (i+1) +'_email');
      let phone = document.getElementById('contact_refugees_application_applicants_attributes_'+ (i+1) +'_phone');

      let mandatoryFields = [firstName, lastName, dob, email, phone]
      
      addbtn.addEventListener('click', () => {        
        singleFormContainer[i+1].classList.toggle('refugee-fields--expand');        
        
        mandatoryFields.forEach((el) => {
          el.classList.toggle('required');
          if(el.classList.contains('required')){
            el.setAttribute('required', 'required');
            el.setAttribute('aria-required', 'true');
          }else{
            el.removeAttribute('required');
            el.removeAttribute('aria-required');
          }
        });        
        
        if(singleFormContainer[i+1].classList.contains('refugee-fields--expand')){
          addAnother[i].innerHTML = 'Add another application -'; 
        }else{
          addAnother[i].innerHTML = 'Add another application +';
        }

      });   
    });  
    
    for (let i = 0; i < firstNameFields.length; i++){
      let mandatoryFields = [firstNameFields[i],lastNameFields[i],emailFields[i],phoneFields[i]]      
      mandatoryFields.forEach((el) => {
        if(el.value){
          singleFormContainer[i].classList.add('refugee-fields--expand')
        }
      });
    }

    for (let i = 0; i < accessDropdowns.length; i++){
      if(accessDropdowns[i].value === 'lesson_or_course'){
        accessOptionsFields[i].classList.add('refugee-fields--expand')
      }
    }
    
    for (let i = 1; i < singleFormContainer.length; i++){
      if(singleFormContainer[i].classList.contains('refugee-fields--expand')){
        addAnother[i-1].innerHTML = 'Add another application -'; 
      }else{
        addAnother[i-1].innerHTML = 'Add another application +';
      }
    }

    for (let i = 0; i < singleFormContainer.length; i++){
      let mandatoryFields = [firstNameFields[i],lastNameFields[i],dobFields[i],emailFields[i],phoneFields[i]]
      if(singleFormContainer[i].classList.contains('refugee-fields--expand')){        
        mandatoryFields.forEach((el) => {
          el.classList.add('required');
          el.setAttribute('required', 'required');
          el.setAttribute('aria-required', 'true');    
        });
      }else{
        mandatoryFields.forEach((el) => {
          el.classList.remove('required');
          el.removeAttribute('required');
          el.removeAttribute('aria-required');
        });
      }
    }  
  
  
  }  
};
